import type { DependencyList, Dispatch, ReactNode } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import { DocSearchType } from "./DocBox/DocBox";

type PortalBoxContextProps = {
  wiki?: {
    wikiSearch?: (term: string, searchId: string) => void;
  };
  doc?: {
    docSearch?: ({ sourceId, type }: DocSearchType) => void;
  };
};

const defaultContextValue: PortalBoxContextProps = {
  wiki: {},
  doc: {},
};

const PortalBoxContext = createContext<{
  state: PortalBoxContextProps;
  dispatch: Dispatch<{
    areas: keyof PortalBoxContextProps;
    data: PortalBoxContextProps[keyof PortalBoxContextProps];
  }>;
}>({ dispatch: () => null, state: defaultContextValue });

function reducer<TKey extends keyof PortalBoxContextProps>(
  state: PortalBoxContextProps,
  payload: { areas: TKey; data: PortalBoxContextProps[TKey] }
) {
  return { ...state, [payload.areas]: { ...payload.data } };
}

function PortalBoxProvider({ children }: { children: ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(reducer, defaultContextValue);
  const value = { state, dispatch };

  return (
    <PortalBoxContext.Provider value={value}>
      {children}
    </PortalBoxContext.Provider>
  );
}

function usePortalBox<TKey extends keyof PortalBoxContextProps>(
  optsProvider: () => { areas: TKey; data: PortalBoxContextProps[TKey] },
  deps: DependencyList
): void {
  const { dispatch } = useContext(PortalBoxContext);
  useEffect(() => {
    dispatch(optsProvider());
  }, [dispatch, ...deps]);
}

export { PortalBoxContext, PortalBoxProvider, usePortalBox };
