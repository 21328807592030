import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthContext, { AuthContextInterface } from "../utils/Auth/context";
import { useSnackbar } from "notistack";

const SignupSchema = Yup.object().shape({
  email: Yup.string(),
  // .email("Invalid email")
  // .required("Required"),
  password: Yup.string().required("No password provided."),
  // .min(8, "Password is too short - should be 8 chars minimum.")
  // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

function LoginForm() {
  // Applicazione Context
  const { signIn } = useContext(AuthContext) as AuthContextInterface;
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      try {
        await signIn(values.email, values.password);
      } catch (e) {
        enqueueSnackbar("Impossibile loggarsi", { variant: "error" });
      }
    },
  });

  return (
    <Box
      sx={{
        borderRadius: "4px",
        display: "block",
        maxWidth: "38ch",
      }}
    >
      {formik.isSubmitting && (
        <LinearProgress sx={{ borderRadius: "4px 4px 0px 0px" }} />
      )}
      <Paper sx={{ p: 2, backgroundColor: "transparent" }} elevation={0}>
        <h2 className="font-mono text-center mb-2 text-lg">
          Benvenuto su Themis
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            sx={{ width: "100%", mb: 2 }}
            variant="standard"
            disabled={formik.isSubmitting}
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              fullWidth
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              aria-describedby="email-helper-text"
              autoComplete="email"
            />
            <FormHelperText id="email-helper-text">
              {formik.touched.email && formik.errors.email}
            </FormHelperText>
          </FormControl>
          <FormControl
            sx={{ width: "100%", mb: 2 }}
            variant="standard"
            disabled={formik.isSubmitting}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              fullWidth
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="password-helper-text"
              autoComplete="current-password"
            />
            <FormHelperText id="password-helper-text">
              {formik.touched.password && formik.errors.password}
            </FormHelperText>
          </FormControl>
          <p className="font-mono text-right mb-2 text-xs">
            Password Dimenticata?
          </p>
          <Button
            sx={{ marginTop: 3 }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting}
          >
            Accedi
          </Button>
          <p className="font-mono text-center mb-2 text-sm mt-8">oppure</p>
          <p className="font-mono text-center mb-2 text-base mt-6 underline cursor-pointer">
            Crea un account
          </p>
        </form>
      </Paper>
    </Box>
  );
}

export default LoginForm;
