import { createContext } from "react";

export interface AuthContextInterface {
  signIn: (userName: string, password: string) => Promise<void>;
  signOut: () => void;
}

// Context
const AuthContext = createContext<AuthContextInterface | null>(null);

export default AuthContext;
