import { ReactNode, createContext, useMemo, useState } from "react";
import { MenuBox } from "./FloatingMenu";
import { useOutsideClick } from "$root/utils/Hooks/useOutsideClick";
import { PortalFloatingMenu } from "./PortalFloatingMenu";

type ContextMenuProviderProps = {
  children: ReactNode;
};

export interface ContextMenuInterface {
  setClicked: (value: boolean) => void;
  setPoints: (value: { x: number; y: number }) => void;
  setText: (value?: string) => void;
}

// Context
export const ContextMenuContext = createContext<ContextMenuInterface | null>(
  null
);

//FIXME refactor using Context and a unique menú element
export default function ContextMenuProvider({
  children,
}: ContextMenuProviderProps) {
  const [text, setText] = useState<string | undefined>();
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const extRef = useOutsideClick(() => {
    setClicked(false);
  });

  const ctxValues = useMemo(
    () => ({
      setClicked,
      setPoints,
      setText,
    }),
    []
  );

  return (
    <ContextMenuContext.Provider value={ctxValues}>
      <PortalFloatingMenu position={points} show={clicked}>
        <div ref={extRef}>
          <MenuBox selectedContent={text} />
        </div>
      </PortalFloatingMenu>
      {children}
    </ContextMenuContext.Provider>
  );
}
