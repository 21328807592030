import { useRef, useEffect } from "react";

/**
 * CUSTOM HOOK (CLIENT): Hook that alerts clicks outside of an element
 *
 * Example:
 * tsx
 * export const MyComponent = () => {
 *   const ref = useOutsideClick(() => {
 *     console.log('Clicked outside of MyComponent');
 *   });
 *
 *   return (
 *     <div ref={ref}>
 *       My Component
 *     </div>
 *   );
 * };
 *
 *
 * @param callback Action to trigger on outside Element Click.
 */

export const useOutsideClick = (callback: (e?: MouseEvent) => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]);

  return ref;
};
