import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import PageLayout from "../components/PageLayout";
import ResponseBox from "../components/ResponseBox";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Rating,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  autocompleteClasses,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { useApiGen } from "../api/hooks";
import InfoBox from "../components/InfoBox";
import GavelIcon from "@mui/icons-material/Gavel";
import { useNavigate, useParams } from "react-router";
import { useQueryNoRefetch } from "../utils/Hooks/useQueryNoRefetch";
import {
  ConversationsApiFactory,
  QuestionAndAnswer,
  SearchType,
} from "../api/api-gen-py-2";
import EmptyImg from "../assets/Empty.png";
import { useUserStore } from "../store/userStore";
import TuneIcon from "@mui/icons-material/Tune";
import EvModal from "../components/Modal";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { format } from "date-fns";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PaletteColors from "../theme/PaletteColors";
import { PortalBox } from "../functional-areas/portal-box/PortalBox";
import { WikiBox } from "../functional-areas/portal-box/WikiBox/WikiBox";
import { CustomTabPanel, a11yProps } from "../components/Tabs";

const StyledRating = styled(Rating)({
  height: "16px",
  "& .MuiRating-iconFilled": {
    height: "16px",
    color: "#212121",
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
  "& .MuiRating-iconEmpty": {
    height: "16px",
    color: "#212121",
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
});

const AskSchema = Yup.object().shape({
  askText: Yup.string().required("Inserisci una domanda"),
  askContext: Yup.array().min(1, "Seleziona almeno un contesto"),
  askType: Yup.string().required("Seleziona il tipo di search"),
  llmMode: Yup.boolean(),
});

const searchTypes: { key: SearchType; value: SearchType; label: string }[] = [
  {
    key: "Evolved",
    value: "Evolved",
    label: "Evolved",
  },
  {
    key: "Similarity",
    value: "Similarity",
    label: "Similarity",
  },
];

const collectionsArray = [
  "codice-di-procedura-civile",
  "codice-delle-comunicazioni-elettroniche",
  "testo-unico-sul-pubblico-impiego",
  "procreazione-medicalmente-assistita",
  "legge-locazioni-abitative",
  "testo-unico-espropriazioni-pubblica-utilita",
  "disciplina-organica-contratti-lavoro",
  "mediazione-controversie-civili-commerciali",
  "responsabilita-amministrativa-persone-giuridiche",
  "codice-delle-pari-opportunita",
  "codice-dei-contratti-pubblici",
  "legge-diritto-internazionale-privato",
  "disposizioni-accertamento-adesione-conciliazione-giudiziale",
  "legge-biotestamento",
  "legge-sull-adozione",
  "codice-degli-appalti",
  "disposizioni-riscossione-imposte-redditi",
  "decreto-cura-italia",
  "norme-sui-licenziamenti-individuali",
  "codice-terzo-settore",
  "legge-sul-procedimento-amministrativo",
  "codice-del-processo-tributario",
  "disposizioni-per-attuazione-del-codice-civile",
  "codice-della-nautica-da-diporto",
  "codice-crisi-impresa",
  "codice-della-proprieta-industriale",
  "lavoro-agile",
  "testo-unico-imposta-registro",
  "legge-quadro-sul-volontariato",
  "ordinamento-organi-speciali-giurisdizione-tributaria",
  "codice-dell-amministrazione-digitale",
  "testo-unico-iva",
  "legge-professione-forense",
  "legge-equo-canone",
  "costituzione",
  "codice-antimafia",
  "legge-ordinamento-penitenziario",
  "disposizioni-per-attuazione-codice-procedura-civile",
  "testo-unico-pubblica-sicurezza",
  "codice-civile",
  "legge-onlus",
  "testo-unico-sostegno-maternita-paternita",
  "regolamento-privacy-ue",
  "disposizioni-accertamento-imposte-redditi",
  "decreto-semplificazioni-bis",
  "testo-unico-bancario",
  "disposizioni-urgenti-materia-fiscale",
  "testo-unico-edilizia",
  "affido-condiviso",
  "testo-unico-societa-partecipazione-pubblica",
  "decreto-lavoro-2023",
  "testo-unico-immigrazione",
  "codice-dell-ambiente",
  "processo-penale-minorile",
  "statuto-contribuente",
  "testo-unico-piante-officinali",
  "testo-unico-intermediazione-finanziaria",
  "legge-cirinna",
  "codice-di-giustizia-contabile",
  "testo-unico-agricoltura",
  "codice-dei-beni-culturali-e-del-paesaggio",
  "disposizioni-per-attuazione-codice-procedura-penale",
  "codice-penale",
  "legge-sui-reati-tributari",
  "testo-unico-enti-locali",
  "disciplina-delle-associazioni-di-promozione-sociale",
  "codice-della-strada",
  "legge-fallimentare",
  "testo-unico-stupefacenti",
  "nuovo-codice-appalti",
  "regolamento-posta-elettronica-certificata",
  "codice-della-privacy",
  "ricorsi-amministrativi",
  "codice-protezione-civile",
  "legge-aborto",
  "codice-del-turismo",
  "testo-unico-sicurezza-sul-lavoro",
  "statuto-lavoratori",
  "finanza-enti-territoriali",
  "contratto-lavoro-tutele-crescenti",
  "codice-del-consumo",
  "decreto-rilancio",
  "disposizioni-sanzioni-amministrative-violazioni-norme-tributarie",
  "disposizioni-sviluppo-proprieta-coltivatrice",
  "decreto-sostegni",
  "codice-delle-assicurazioni-private",
  "preleggi",
  "legge-diritto-autore",
  "testo-unico-successioni-donazioni",
  "codice-di-procedura-penale",
  "norme-contratti-agrari",
  "resposabilita-professionale-personale-sanitario",
  "legge-sul-divorzio",
  "disposizioni-transitorie-codice-penale",
  "testo-unico-assicurazione-degli-infortuni-sul-lavoro",
  "legge-104",
  "testo-unico-imposte-redditi",
  "ammortizzatori-sociali",
  "codice-del-processo-amministrativo",
  "organizzazione-orario-lavoro",
];

type LLMModels = "LawGPT_1.0_short" | "LawGPT_1.0_long";
const llmModels: {
  key: LLMModels;
  value: LLMModels;
  label: string;
}[] = [
  {
    key: "LawGPT_1.0_short",
    value: "LawGPT_1.0_short",
    label: "LawGPT 1.0 short",
  },
  {
    key: "LawGPT_1.0_long",
    value: "LawGPT_1.0_long",
    label: "LawGPT 1.0 long",
  },
];

function AskBox({
  searchId,
  updateChatMessages,
}: {
  searchId?: string;
  updateChatMessages: (response: {
    question: string;
    response: QuestionAndAnswer;
  }) => void;
}) {
  const conversationApi = useApiGen(ConversationsApiFactory);
  const { uid } = useUserStore((state) => state.UserData);

  const formik = useFormik({
    initialValues: {
      askText: "",
      askType: "Evolved",
      askContext: [],
    } satisfies {
      askText: string;
      askType: SearchType;
      askContext: { key: SearchType; value: SearchType; label: string }[];
    },
    validationSchema: AskSchema,
    onSubmit: async (values) => {
      try {
        const response =
          await conversationApi.newQuestionConversationsNewQuestionUserUuidPost(
            uid,
            values.askText,
            values.askType,
            values.askContext.map(
              (el: { key: SearchType; value: SearchType; label: string }) =>
                el.value
            ),
            searchId
          );
        updateChatMessages({
          question: values.askText,
          response: response.data as QuestionAndAnswer,
        });
      } catch (e) {
        enqueueSnackbar("Servizio al momento non disponibile", {
          variant: "error",
        });
      }
    },
  });

  const collections = useMemo(() => {
    return collectionsArray.map((el) => {
      const repName = el.replaceAll(/-|_/g, " ");
      const cleanedName = repName.charAt(0).toUpperCase() + repName.slice(1);

      return {
        key: el,
        value: el,
        label: cleanedName,
      };
    });
  }, []);

  return (
    <>
      {formik.isSubmitting && (
        <LinearProgress sx={{ borderRadius: "4px 4px 0px 0px" }} />
      )}
      <Paper sx={{ p: 2, backgroundColor: "transparent" }} elevation={0}>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-12 gap-3 md:flex">
            <div className="col-span-6 md:fit-content">
              <FormControl
                sx={{ width: "100%", pr: 2 }}
                disabled={formik.isSubmitting}
              >
                <EvModal
                  title="Contesto"
                  description="Seleziona il contesto della domanda"
                  activator={
                    <div className="bg-white border border-[#000] rounded h-[56px] flex items-center px-6">
                      Select Context{" "}
                      {Boolean(formik.values.askContext.length) &&
                        `(${formik.values.askContext.length})`}
                    </div>
                  }
                  closeCustomComponent={
                    <div className="bg-transparent border border-[#000] rounded flex items-center px-4 py-2">
                      Confirm
                    </div>
                  }
                >
                  <div className="py-4">
                    <Autocomplete
                      multiple
                      id="askContext"
                      options={collections}
                      getOptionLabel={(option) => option.label}
                      value={formik.values.askContext}
                      limitTags={5}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Context"
                          placeholder="Add"
                        />
                      )}
                      onChange={(event, newValue: typeof collections) => {
                        formik.setFieldValue("askContext", newValue);
                      }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{
                            background: PaletteColors.AGwhite,
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                          }}
                        >
                          {children}
                        </Paper>
                      )}
                    />
                  </div>
                </EvModal>
                <FormHelperText id="askText-helper-text">
                  {formik.touched.askContext && formik.errors.askContext}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-span-6 md:w-[150px]">
              <FormControl
                sx={{ width: "100%", pr: 2 }}
                disabled={formik.isSubmitting}
              >
                <InputLabel id="askType-select-label">Type</InputLabel>
                <Select
                  labelId="askType-select-label"
                  id="askType"
                  name="askType"
                  value={formik.values.askType}
                  label="Type"
                  onChange={formik.handleChange}
                >
                  {searchTypes.map((searchType) => (
                    <MenuItem key={searchType.key} value={searchType.value}>
                      {searchType.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="askType-helper-text">
                  {formik.touched.askType && formik.errors.askType}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="flex col-span-12 md:grow">
              <FormControl
                sx={{ width: "100%", pr: 2 }}
                variant="standard"
                disabled={formik.isSubmitting}
              >
                <TextField
                  fullWidth
                  id="askText"
                  name="askText"
                  placeholder="Inserisci il tuo quesito..."
                  value={formik.values.askText}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.askText && Boolean(formik.errors.askText)
                  }
                  aria-describedby="askText-helper-text"
                />
                <FormHelperText id="askText-helper-text">
                  {formik.touched.askText && formik.errors.askText}
                </FormHelperText>
              </FormControl>
              <div className="flex items-center">
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={formik.isSubmitting}
                  size="medium"
                >
                  Ask
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Paper>
    </>
  );
}

function AdvancedSettings({
  settings,
  updateAdvancedSettings,
}: {
  settings: {
    askLLMVersion: LLMModels;
    askType: SearchType[];
    viewOnlyLast: boolean;
  };
  updateAdvancedSettings: (value: {
    askLLMVersion: LLMModels;
    askType: SearchType[];
    viewOnlyLast: boolean;
  }) => void;
}) {
  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const formik = useFormik({
    initialValues: settings,
    onSubmit: (values, { setSubmitting }) => {
      try {
        updateAdvancedSettings(values);
        setSubmitting(false);
      } catch (e) {
        enqueueSnackbar("Servizio al momento non disponibile", {
          variant: "error",
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <div className="flex space-x-2 items-center">
                  <TuneIcon />
                  <span>Search settings</span>
                </div>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <div className="flex space-x-2 items-center">
                  <FilterAltOutlinedIcon />
                  <span>Filters</span>
                </div>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        {formik.isSubmitting && (
          <LinearProgress sx={{ borderRadius: "4px 4px 0px 0px" }} />
        )}
        <CustomTabPanel value={tab} index={0}>
          <div>
            <h3 className="mb-4">Model Version</h3>
            <FormControl
              sx={{ width: "300px", pr: 2 }}
              disabled={formik.isSubmitting}
            >
              <InputLabel id="askLLMVersion-select-label">
                Model Version
              </InputLabel>
              <Select
                labelId="askLLMVersion-select-label"
                id="askLLMVersion"
                name="askLLMVersion"
                value={formik.values.askLLMVersion}
                label="LLM Version"
                onChange={formik.handleChange}
              >
                {llmModels.map((llmModel) => (
                  <MenuItem key={llmModel.key} value={llmModel.value}>
                    {llmModel.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="askText-helper-text">
                {formik.touched.askLLMVersion && formik.errors.askLLMVersion}
              </FormHelperText>
            </FormControl>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <div>
            <h3 className="mb-4">Search Type</h3>
            <FormControl
              sx={{ width: "300px", pr: 2 }}
              disabled={formik.isSubmitting}
            >
              <InputLabel id="askType-select-label">Type</InputLabel>
              <Select
                labelId="askType-select-label"
                id="askType"
                name="askType"
                value={formik.values.askType}
                label="Type"
                onChange={formik.handleChange}
                multiple
              >
                {searchTypes.map((searchType) => (
                  <MenuItem key={searchType.key} value={searchType.value}>
                    {searchType.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="askType-helper-text">
                {formik.touched.askType && formik.errors.askType}
              </FormHelperText>
            </FormControl>
          </div>
        </CustomTabPanel>
        <Button
          variant="outlined"
          type="submit"
          disabled={formik.isSubmitting}
          size="medium"
        >
          Apply
        </Button>
      </Box>
    </form>
  );
}

const EvolvedSearch: React.FunctionComponent = () => {
  const conversationApi = useApiGen(ConversationsApiFactory);
  const navigate = useNavigate();
  const { uid } = useUserStore((state) => state.UserData);
  const { searchId } = useParams();
  const [showHistory, setShowHistory] = useState(true);
  const [activeMessages, setActiveMessages] = useState<string[]>([]);
  const [showPortal, setShowPortal] = useState(false);
  const [settings, setSettings] = useState<{
    askLLMVersion: LLMModels;
    askType: SearchType[];
    viewOnlyLast: boolean;
  }>({
    askLLMVersion: "LawGPT_1.0_short",
    askType: ["Evolved", "Similarity"],
    viewOnlyLast: false,
  });

  const switchOnlyLast = useCallback(
    (value: boolean) => {
      setSettings({ ...settings, viewOnlyLast: value });
    },
    [settings]
  );

  const [chatMessages, setChatMessages] = useState<
    { question: string; response: QuestionAndAnswer }[]
  >([]);

  const {
    data: searches,
    isLoading,
    isError,
    refetch,
  } = useQueryNoRefetch(["SearchEvolvedQuery", searchId], {
    enabled: Boolean(searchId),
    retry: false,
    queryFn: async () => {
      const responses =
        await conversationApi.getConversationConversationsConversationConversationUuidGet(
          searchId ?? ""
        );
      return responses;
    },
    onError(e) {
      navigate("home");
      enqueueSnackbar("Conversation not found", {
        variant: "error",
      });
    },
    onSuccess({ data: { search_history } }) {
      const historyChatMessages = search_history.map((el) => ({
        question: el.question,
        response: el,
      }));
      setChatMessages(historyChatMessages);
      const lastMessageUUID = historyChatMessages.at(-1)?.response.uuid;
      if (lastMessageUUID)
        setActiveMessages([...new Set([...activeMessages, lastMessageUUID])]);
    },
  });

  const filterdChatMessages = useMemo(() => {
    return chatMessages.filter((el, index) => {
      const notToShow =
        index + 1 !== chatMessages.length && settings.viewOnlyLast;
      return (
        settings.askType.some((f) => f === el.response.search_type) &&
        !notToShow
      );
    });
  }, [chatMessages, settings.askType, settings.viewOnlyLast]);

  const refs = filterdChatMessages.reduce(
    (acc: Record<string, React.RefObject<HTMLDivElement>>, value) => {
      acc[value.response.uuid ?? ""] = React.createRef();
      return acc;
    },
    {}
  );

  const scrollTo = useCallback(
    (id: string) => {
      const currentRef = refs[id as keyof typeof refs];
      if (currentRef.current) {
        currentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    [refs]
  );

  useLayoutEffect(() => {
    const lastElement = filterdChatMessages.at(-1);
    if (lastElement?.response.uuid) {
      scrollTo(lastElement.response.uuid ?? "");
    }
  }, [filterdChatMessages.length]);

  return (
    <>
      <PortalBox
        open={showPortal}
        onClose={() => setShowPortal(false)}
        setShowPortal={(value) => setShowPortal(value)}
        width={840}
        styleMode="modal"
        title="Law Wiki"
      >
        {(childrenProps) => <WikiBox {...childrenProps} />}
      </PortalBox>
      <PageLayout>
        <div className="relative flex min-h-[calc(100vh-64px)] bg-white">
          <div className="flex flex-none basis-full md:basis-3/4 md:grow flex-col">
            <div className="z-10 flex h-[60px] flex-col justify-center shadow-md bg-white">
              <div className="flex justify-between px-4 items-center">
                <h2 className="font-bold text-lg">Evolved Search</h2>
                <div className="flex space-x-2 items-center">
                  <div className="pr-2">
                    <Stack spacing={2} alignItems="center">
                      <ToggleButtonGroup
                        size="small"
                        exclusive
                        aria-label="Only last message"
                        value={settings.viewOnlyLast}
                        onChange={(_e, value) => switchOnlyLast(value)}
                      >
                        <ToggleButton
                          disabled={!settings.viewOnlyLast}
                          value={false}
                          key="multi"
                        >
                          <FileCopyOutlinedIcon />
                        </ToggleButton>
                        <ToggleButton
                          disabled={settings.viewOnlyLast}
                          value={true}
                          key="single"
                        >
                          <InsertDriveFileOutlinedIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </div>
                  <button
                    type="button"
                    className="bg-transparent border border-[#000] rounded p-[7px]"
                  >
                    <SearchIcon />
                  </button>
                  <EvModal
                    activator={
                      <div className="bg-transparent border border-[#000] rounded p-[7px]">
                        <TuneIcon />
                      </div>
                    }
                  >
                    <AdvancedSettings
                      settings={settings}
                      updateAdvancedSettings={(value) => setSettings(value)}
                    />
                  </EvModal>
                  {!showHistory && (
                    <div className="pl-2">
                      <button
                        className="bg-transparent hidden md:block"
                        onClick={() => setShowHistory(true)}
                      >
                        <FormatIndentDecreaseIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="relative no-scrollbar my-2 flex-grow overflow-y-scroll">
              <div className="h-0 block">
                <div className="mb-2 px-4 py-2">
                  {filterdChatMessages.length > 0 ? (
                    (filterdChatMessages ?? []).map((message, index) => {
                      const isActive = activeMessages.includes(
                        message.response.uuid ?? ""
                      );
                      return (
                        <ResponseBox
                          key={`${message.response.uuid}-${index}-${isActive}`}
                          ref={refs[message.response.uuid ?? ""]}
                          response={{
                            query: {
                              text: message.question,
                              context: "Not Defined",
                              tagsOrKeywors: [],
                              articlesReferences: [],
                              temperature: 2,
                              chainLogic: "",
                            },
                            questionContext: message.response.question_context,
                            abstract: {
                              text: message.response.abstract,
                              score: 1,
                            },
                            sources: message.response.sources,
                            correlatedQuestions: [],
                            date: format(
                              new Date(message.response.date),
                              "dd-MM-yyyy"
                            ),
                            uuid: message.response.uuid,
                            feedback: message.response.feedback ?? undefined,
                            searchType: message.response.search_type,
                          }}
                          active={isActive}
                          searchId={searchId}
                          onFeedbackSave={() => {
                            refetch();
                          }}
                        />
                      );
                    })
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div>
                        <Box
                          component="img"
                          className="mb-2"
                          sx={{
                            height: 240,
                          }}
                          alt="The house from the offer."
                          src={EmptyImg}
                        />
                        <h2 className="text-center font-bold text-lg mb-2">
                          You have no searches!
                        </h2>
                        <p className="text-center">
                          Enter your question below to begin your search
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="z-10 h-[160px] md:h-[90px] bg-white ring-1 ring-gray-300">
              <AskBox
                searchId={searchId}
                updateChatMessages={(message) => {
                  setChatMessages([
                    ...chatMessages,
                    {
                      question: message.question,
                      response: message.response,
                    },
                  ]);
                  if (message.response.uuid) {
                    setActiveMessages([
                      ...new Set([...activeMessages, message.response.uuid]),
                    ]);
                  }
                }}
              />
            </div>
          </div>
          {showHistory && (
            <div className="hidden border vorder-gray-200 md:flex md:basis-1/4 flex-col bg-white">
              <div className="z-10 flex h-[60px] flex-col justify-center bg-white align-middle shadow-md">
                <div className="flex justify-between px-4">
                  <h2 className="font-bold text-lg">History</h2>
                  <button
                    className="bg-transparent"
                    onClick={() => setShowHistory(false)}
                  >
                    <FormatIndentIncreaseIcon />
                  </button>
                </div>
              </div>
              <div className="no-scrollbar my-2 flex-grow overflow-y-scroll ">
                <div className="h-0">
                  {filterdChatMessages.map(({ response }) => (
                    <div
                      key={response.uuid}
                      className="mb-1 px-4 pb-1"
                      onClick={() => {
                        if (response.uuid) {
                          setActiveMessages([
                            ...new Set([...activeMessages, response.uuid]),
                          ]);
                          scrollTo(response.uuid);
                        }
                      }}
                    >
                      <InfoBox
                        icon={
                          <GavelIcon fontSize="small" sx={{ marginRight: 1 }} />
                        }
                        className="shadow-sm border border-gray-200 rounded-md"
                        title={
                          <div className="flex space-x-1">
                            {response.search_type && (
                              <Chip
                                label={response.search_type}
                                variant="outlined"
                                size="small"
                              />
                            )}
                            {response.feedback && (
                              <Tooltip title={response.feedback.text} arrow>
                                <div className="rounded-full border border-[#b2b2b2] flex justify-center align-middle fit-content px-1 py-[3px] h-full">
                                  <StyledRating
                                    disabled={true}
                                    value={response.feedback.value}
                                    icon={<StarIcon />}
                                    emptyIcon={<StarBorderIcon />}
                                  />
                                </div>
                              </Tooltip>
                            )}
                            <span className="text-xs">
                              {format(new Date(response.date), "dd-MM-yyyy")}
                            </span>
                          </div>
                        }
                      >
                        <Tooltip title={response.question} arrow>
                          <span className="text-sm">{`${response.question.substring(
                            0,
                            100
                          )}...`}</span>
                        </Tooltip>
                      </InfoBox>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default EvolvedSearch;
