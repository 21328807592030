import { useApiGen } from "$root/api/hooks";
import { AdeDocsApiFactory } from "$root/api/api-gen-py-2";
import { useQueryNoRefetch } from "$root/utils/Hooks/useQueryNoRefetch";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { CustomTabPanel, a11yProps } from "$root/components/Tabs";
import DisabledWallWrapper from "$root/components/DisabledWallWrapper";
import { PortalBoxContext } from "../portal-box/context";

export default function HomeNews() {
  const adeApi = useApiGen(AdeDocsApiFactory);
  // Result Tabs
  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const { state } = useContext(PortalBoxContext);

  const {
    data: adeCircData,
    isLoading: adeCircIsLoading,
    isError: adeCircIsError,
  } = useQueryNoRefetch(["AdeCircListQuery"], {
    queryFn: async () => {
      const listResponse =
        await adeApi.listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet();
      const mappedCirc = listResponse.data.map((el) => ({
        type: "circolari" as "risoluzioni" | "circolari",
        title: el.title,
        description: el.description,
        url: el.link_url,
        date: el.date,
        uuid: el._id,
      }));
      return mappedCirc;
    },
    onError(e) {
      console.log(e);
    },
  });

  const {
    data: adeRisData,
    isLoading: adeRisIsLoading,
    isError: adeRisIsError,
  } = useQueryNoRefetch(["AdeRisListQuery"], {
    queryFn: async () => {
      const listResponse =
        await adeApi.listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet();
      const mappedCirc = listResponse.data.map((el) => ({
        type: "risoluzioni" as "risoluzioni" | "circolari",
        title: el.title,
        description: el.description,
        url: el.link_url,
        date: el.date,
        uuid: el._id,
      }));
      return mappedCirc;
    },
    onError(e) {
      console.log(e);
    },
  });

  return (
    <div className="h-full">
      <Tabs
        value={tab}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          label={
            <div className="flex space-x-2 items-center">
              <span>Circolari</span>
            </div>
          }
          {...a11yProps(0)}
        />
        <Tab
          label={
            <div className="flex space-x-2 items-center">
              <span>Risoluzioni</span>
            </div>
          }
          {...a11yProps(1)}
        />
      </Tabs>
      <div className="h-[calc(100%-60px)] overflow-auto px-4 no-scrollbar min-h-[400px] max-h-[500px]">
        <CustomTabPanel value={tab} index={0}>
          {adeCircIsLoading || adeCircIsError ? (
            <DisabledWallWrapper
              disabledWallWrapperClassNames="h-full flex justify-center items-center  relative"
              fullDimension
              isActive
              variant={{
                iconWall: adeCircIsError ? "noData" : "loadingData",
                opaque: true,
              }}
            />
          ) : (
            <List>
              {adeCircData?.map((news, i) => {
                return (
                  <Fragment key={i}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <button
                            className="text-black font-bold underline"
                            onClick={() => {
                              if (!state.doc?.docSearch) {
                                return;
                              }
                              state.doc?.docSearch({
                                sourceId: news.uuid,
                                type: news.type,
                              });
                            }}
                            type="button"
                          >
                            {news.title}
                          </button>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            ></Typography>
                            {news.description}
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </Fragment>
                );
              })}
            </List>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          {adeRisIsLoading || adeRisIsError ? (
            <DisabledWallWrapper
              disabledWallWrapperClassNames="h-full flex justify-center items-center  relative"
              fullDimension
              isActive
              variant={{
                iconWall: adeRisIsError ? "noData" : "loadingData",
                opaque: true,
              }}
            />
          ) : (
            <List>
              {adeRisData?.map((news, i) => {
                return (
                  <Fragment key={i}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <button
                            className="text-black font-bold underline"
                            onClick={() => {
                              if (!state.doc?.docSearch) {
                                return;
                              }
                              state.doc?.docSearch({
                                sourceId: news.uuid,
                                type: news.type,
                              });
                            }}
                            type="button"
                          >
                            {news.title}
                          </button>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            ></Typography>
                            {news.description}
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </Fragment>
                );
              })}
            </List>
          )}
        </CustomTabPanel>
      </div>
    </div>
  );
}
