import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SearchIcon from "@mui/icons-material/Search";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import InfoBox from "../components/InfoBox";
import PageLayout from "../components/PageLayout";
import { useUserStore } from "../store/userStore";
import { EvolvedSearchListTable } from "./EvolvedSearchList";
import HomeNews from "$root/functional-areas/dashboard/HomeNews";
import { PortalBox } from "$root/functional-areas/portal-box/PortalBox";
import { DocBox } from "$root/functional-areas/portal-box/DocBox/DocBox";

export interface IUserStatus {
  isFirstTime: boolean;
  isAllDataRegistered: boolean;
  isParkingActive: boolean;
}

const Home: React.FunctionComponent = () => {
  const UserData = useUserStore((state) => state.UserData);
  const navigate = useNavigate();
  const [showPortal, setShowPortal] = useState(false);

  return (
    <>
      <PortalBox
        open={showPortal}
        onClose={() => setShowPortal(false)}
        setShowPortal={(value) => setShowPortal(value)}
        width={840}
        styleMode="modal"
        title="Doc"
      >
        {(childrenProps) => <DocBox {...childrenProps} />}
      </PortalBox>
      <PageLayout>
        <div className="p-4">
          <div className="flex justify-between items-center  mb-4">
            <h2 className="font-semibold text-3xl">{UserData.username}</h2>
            <button className="flex drop-shadow-md rounded p-3 bg-white border-l-4 border-cs-blue align-middle">
              <CalendarMonthOutlinedIcon />
              <span className="pl-2 text-base">{`${format(
                new Date(),
                "PPP"
              )}`}</span>
            </button>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <InfoBox
              icon={<SearchIcon sx={{ marginRight: 1 }} />}
              title="Simple Search"
              className="border-t-4 border-cs-blue h-full"
              description="La funzione di ricerca tra le fonti legislative è una risorsa preziosa per individuare e consultare le leggi, i regolamenti e altri atti normativi che regolano varie aree del diritto. Grazie a questa funzione, gli utenti possono accedere facilmente a un vasto database di informazioni legali e trovare rapidamente ciò di cui hanno bisogno. Inizia una ricerca."
              onClick={() => navigate(`/evolved-search`)}
            />
            <InfoBox
              icon={<TroubleshootIcon sx={{ marginRight: 1 }} />}
              title="Evolved Search"
              className="border-t-4 border-cs-blue  h-full"
              description="L'intelligenza artificiale applicata alla ricerca tra le fonti legislative consente di effettuare ricerche potenti ed estremamente accurate. Grazie alla capacità dell'AI di comprendere il linguaggio umano e di apprendere dai dati, questa funzione può interpretare le domande degli utenti e fornire risultati pertinenti che soddisfano le loro esigenze."
              onClick={() => navigate(`/evolved-search`)}
            />
            <InfoBox
              icon={<GavelIcon sx={{ marginRight: 1 }} />}
              title="Law Database"
              className="border-t-4 border-cs-blue h-full"
              description="Questa sezione offre un completo e organizzato database di leggi, norme, sentenze e codici, fornendo agli utenti un accesso rapido e conveniente a una vasta gamma di risorse legali. Grazie alla sua struttura dettagliata e intuitiva, gli utenti possono trovare facilmente le informazioni legali necessarie per una comprensione accurata e aggiornata del diritto"
            />
            <InfoBox
              icon={<HandshakeIcon sx={{ marginRight: 1 }} />}
              title={`Benvenuto su Law GPT`}
              className="border-t-4 border-cs-blue flex flex-col h-full"
            >
              <p>
                Law GPT nasce per supportare il professionista nella ricerca
                evoluta su codici, sentenze e casistiche legali sfruttando la
                potenza dei modelli di AI generativa.
              </p>

              <div className="flex justify-end space-x-2 mt-auto border-gray border-t border-solid pt-4">
                <button
                  className="border border-cs-blue rounded py-2 px-4"
                  onClick={() => navigate(`/evolved-search`)}
                  type="button"
                >
                  Inizia una ricerca
                </button>
                <button
                  type="button"
                  className="border border-cs-blue rounded py-2 px-4"
                  onClick={(e) => {
                    window.location.href = "mailto:support@maildadefinire.it";
                    e.preventDefault();
                  }}
                >
                  Contattaci
                </button>
              </div>
            </InfoBox>
            <div className="lg:col-span-3">
              <InfoBox
                icon={<TroubleshootIcon sx={{ marginRight: 1 }} />}
                title="Recent Searches"
                description=""
                onClick={() => navigate(`/evolved-search`)}
                className="flex flex-col h-full"
              >
                <EvolvedSearchListTable className="h-full" />
              </InfoBox>
            </div>
            <InfoBox
              icon={<AnnouncementOutlinedIcon sx={{ marginRight: 1 }} />}
              title="News and Updates"
              //description={`Aggiornato al ${format(new Date(), "dd-MM-yyyy")}`}
              className="border-t-4 border-cs-blue"
            >
              <HomeNews />
            </InfoBox>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default Home;
