import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { StackingContext } from "../global";
import { NodeOrFn, renderNodeOrFn } from "../../utils/Rendering";
import PaletteColors from "../../theme/PaletteColors";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import MaximizeIcon from "@mui/icons-material/Maximize";

type PortalBoxPositions = "left" | "right";
type PortalBoxMode = "modal" | "bar";
type PortalBoxState = "extended" | "default";

type PortalBoxProps = {
  open: boolean;
  setShowPortal: Dispatch<SetStateAction<boolean>>;
  onClose?(): void;
  width: CSSProperties["width"];
  children: NodeOrFn<PortalBoxChildrenProps>;
  position?: PortalBoxPositions;
  styleMode?: PortalBoxMode;
  title?: string;
};

export type PortalBoxChildrenProps = {
  open: boolean;
  setShowPortal: Dispatch<SetStateAction<boolean>>;
  onClose?(): void;
  width: CSSProperties["width"];
  position?: PortalBoxPositions;
  setPosition: Dispatch<SetStateAction<PortalBoxPositions>>;
  setMinimized: Dispatch<SetStateAction<boolean>>;
  styleMode: PortalBoxMode;
};

export function PortalBox({
  open,
  setShowPortal,
  onClose,
  width,
  children,
  styleMode = "bar",
  title,
}: PortalBoxProps): JSX.Element {
  const [position, setPosition] = useState<PortalBoxPositions>("right");
  const [status, setStatus] = useState<PortalBoxState>("default");
  const [minimized, setMinimized] = useState<boolean>(true);

  const childrenProps = useMemo<PortalBoxChildrenProps>(
    () => ({
      open,
      onClose,
      width,
      position,
      setPosition,
      setMinimized,
      setShowPortal,
      styleMode,
    }),
    [onClose, open, position, setShowPortal, styleMode, width]
  );

  const positionProvider = useMemo(
    () => ({
      right: {
        top: 0,
        right: 0,
        bottom: 0,
        left: undefined,
      },
      left: {
        top: 0,
        right: undefined,
        left: 0,
        bottom: 0,
      },
      default: {
        top: undefined,
        right: 0,
        left: undefined,
        bottom: 0,
      },
    }),
    []
  );

  const modeProvider = useMemo(
    () => ({
      modal: {
        ...(minimized || status === "default"
          ? positionProvider["default"]
          : {
              top: "50%",
              left: "50%",
            }),
        transform:
          status === "extended" && !minimized
            ? "translate(-50%, -50%)"
            : undefined,
        width: minimized ? undefined : status === "extended" ? "80%" : width,
        ...(status === "extended"
          ? {
              maxWidth: "80%",
              height: minimized ? undefined : "80vh",
              maxHeight: "80vh",
            }
          : {
              maxWidth: "80%",
              height: minimized ? undefined : "60vh",
              maxHeight: "80vh",
            }),
        borderRadius: 5,
      },
      bar: {
        width: minimized ? undefined : status === "extended" ? "50%" : width,
        ...positionProvider[minimized ? "default" : position],
      },
    }),
    [status, width, positionProvider, minimized, position]
  );

  return (
    <StackingContext.Consumer>
      {({ zIndex }) =>
        createPortal(
          <div
            className={`${
              !open ? "hidden" : ""
            } absolute drop-shadow-lg bg-white`}
            style={{
              zIndex,
              backgroundColor: PaletteColors.AGwhite,
              ...modeProvider[styleMode],
            }}
          >
            <StackingContext.Provider value={{ zIndex: zIndex + 1 }}>
              <>
                <div className="flex justify-between p-4 bg-cs-blue text-white rounded-t-[5px] ">
                  <h2 className="text-lg min-w-[150px]">{title}</h2>
                  <div className="flex space-x-2">
                    <button
                      className="bg-transparent"
                      onClick={() => setMinimized(!minimized)}
                    >
                      {minimized ? (
                        <MaximizeIcon fontSize="small" />
                      ) : (
                        <MinimizeIcon fontSize="small" />
                      )}
                    </button>
                    <button
                      className="bg-transparent"
                      onClick={() => {
                        setStatus(
                          status === "extended" ? "default" : "extended"
                        );
                        if (minimized) {
                          setMinimized(false);
                        }
                      }}
                    >
                      {status === "extended" ? (
                        <CloseFullscreenIcon fontSize="small" />
                      ) : (
                        <OpenInFullIcon fontSize="small" />
                      )}
                    </button>
                    {onClose && (
                      <button
                        className="bg-transparent"
                        onClick={() => onClose()}
                      >
                        <CloseIcon fontSize="small" />
                      </button>
                    )}
                  </div>
                </div>
                <div className={minimized ? "hidden" : "h-[calc(100%-60px)]"}>
                  {renderNodeOrFn(children, childrenProps)}
                </div>
              </>
            </StackingContext.Provider>
          </div>,
          document.body
        )
      }
    </StackingContext.Consumer>
  );
}
