import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { FunctionalityEnum, RoleEnum } from "../api/api-gen-py-2";

export type ThemeModes = "light" | "dark";

export interface IUser {
  uid: string;
  email: string;
  username: string;
  availableServices: FunctionalityEnum[];
  roles: RoleEnum[];
  themeMode: ThemeModes;
}

export interface IUserStore {
  UserData: IUser;
  updateUser: (value: Partial<IUser>) => void;
  removeUser: () => void;
}

const defaultUser: IUser = {
  uid: "",
  email: "",
  username: "",
  availableServices: [],
  roles: [],
  themeMode: "light",
};

export const useUserStore = create<IUserStore>()(
  devtools(
    persist(
      (set) => ({
        UserData: defaultUser,
        updateUser: (value) => {
          set((state) => ({
            UserData: {
              ...state.UserData,
              ...value,
            },
          }));
        },
        removeUser: () => {
          set({
            UserData: defaultUser,
          });
        },
      }),
      { name: "User Store" }
    ),
    { enabled: true }
  )
);
