import { Fragment } from "react";
import MenuItem from "./MenuItem.js";
import { Editor } from "@tiptap/react";
import { IconNames } from "../Icon/index.js";

const MenuBar = ({ editor }: { editor: Editor }) => {
  
  const items = [
    {
      icon: "FormatBold",
      title: "Bold",
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive("bold"),
    },
    {
      icon: "FormatItalic",
      title: "Italic",
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive("italic"),
    },
    {
      icon: "FormatStrikethrough",
      title: "Strike",
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive("strike"),
    },
    {
      icon: "Code",
      title: "Code",
      action: () => editor.chain().focus().toggleCode().run(),
      isActive: () => editor.isActive("code"),
    },
    {
      icon: "BorderColor",
      title: "Highlight",
      action: () => editor.chain().focus().toggleHighlight().run(),
      isActive: () => editor.isActive("highlight"),
    },
    {
      type: "divider",
    },
    {
      icon: "Warning",
      title: "Heading 1",
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive("heading", { level: 1 }),
    },
    {
      icon: "Warning",
      title: "Heading 2",
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive("heading", { level: 2 }),
    },
    // {
    //   icon: "paragraph",
    //   title: "Paragraph",
    //   action: () => editor.chain().focus().setParagraph().run(),
    //   isActive: () => editor.isActive("paragraph"),
    // },
    {
      icon: "FormatListBulleted",
      title: "Bullet List",
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
    },
    {
      icon: "FormatListNumbered",
      title: "Ordered List",
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
    },
    {
      icon: "Code",
      title: "Code Block",
      action: () => editor.chain().focus().toggleCodeBlock().run(),
      isActive: () => editor.isActive("codeBlock"),
    },
    {
      type: "divider",
    },
    {
      icon: "FormatQuote",
      title: "Blockquote",
      action: () => editor.chain().focus().toggleBlockquote().run(),
      isActive: () => editor.isActive("blockquote"),
    },
    // {
    //   icon: "Warning",
    //   title: "Horizontal Rule",
    //   action: () => editor.chain().focus().setHorizontalRule().run(),
    // },
    {
      type: "divider",
    },
    {
      icon: "HorizontalRule",
      title: "Hard Break",
      action: () => editor.chain().focus().setHardBreak().run(),
    },
    {
      icon: "FormatClear",
      title: "Clear Format",
      action: () => editor.chain().focus().clearNodes().unsetAllMarks().run(),
    },
    {
      type: "divider",
    },
    {
      icon: "Undo",
      title: "Undo",
      action: () => editor.chain().focus().undo().run(),
    },
    {
      icon: "Redo",
      title: "Redo",
      action: () => editor.chain().focus().redo().run(),
    },
  ] satisfies Array<{
    type: "divider"
  } | { icon: IconNames, title: string, action(): boolean, isActive?(): boolean  }>;

  return (
    <div className="px-4 py-2 flex space-x-2 editor__header">
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.type === "divider" ? (
            <div className="divide-y" />
          ) : (
            <MenuItem {...item}  />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MenuBar;