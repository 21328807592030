import StarterKit from "@tiptap/starter-kit";
import { Highlight } from "@tiptap/extension-highlight";
import { Markdown } from 'tiptap-markdown';
import { EditorContent, useEditor } from "@tiptap/react";
import MenuBar from "./MenuBar";
import "./style.scss"

const Editor = () => {
  const editor = useEditor({
    extensions: [StarterKit, Highlight, Markdown],
    content: `<p>Example Text</p>`
  });

  console.log(editor?.storage.markdown.getMarkdown())
  return (
    <div className="editor">
      {editor && <MenuBar editor={editor} />}
      <EditorContent editor={editor} className="editor__content"  />
    </div>
  );
};

export default Editor;
