import { useCallback, useEffect, useState } from "react";
import { PortalBoxChildrenProps } from "../PortalBox";
import { usePortalBox } from "../context";
import { useQueryNoRefetch } from "../../../utils/Hooks/useQueryNoRefetch";
import { CustomLoader } from "../../../components/CustomLoader";
import { AdeDocsApiFactory } from "$root/api/api-gen-py-2";
import { useApiGen } from "$root/api/hooks";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import classNames from "classnames";

export type DocSearchType = {
  sourceId: string;
  type: "circolari" | "risoluzioni";
};

export function DocBox({
  open,
  onClose,
  width,
  position,
  setPosition,
  setMinimized,
  setShowPortal,
  styleMode,
}: PortalBoxChildrenProps): JSX.Element {
  const adeApi = useApiGen(AdeDocsApiFactory);
  const [source, setSource] = useState<{
    sourceId: string;
    type: "circolari" | "risoluzioni";
  } | null>(null);
  const [page, setPage] = useState<number>(0);

  const {
    data: result,
    isFetching,
    isError,
  } = useQueryNoRefetch(["SearchWikiQuery", source], {
    enabled: Boolean(source),
    retry: false,
    queryFn: async () => {
      if (!source) {
        return;
      }
      const docsOrigin =
        source?.type === "circolari"
          ? await adeApi.showDocCircolareAdeDocumentsCircolariDocIdGet(
              source.sourceId
            )
          : await adeApi.showDocCircolareAdeDocumentsRisoluzioniDocIdGet(
              source.sourceId
            );
      return docsOrigin.data;
    },
    onError(e) {
      console.warn("Not able to search, retry later");
    },
  });

  const docSearch = useCallback(
    ({ sourceId, type }: DocSearchType) => {
      setShowPortal(true);
      setMinimized(false);
      setSource({ sourceId, type });
    },
    [setMinimized, setShowPortal]
  );

  usePortalBox(
    () => ({
      areas: "doc",
      data: {
        docSearch,
      },
    }),
    []
  );

  useEffect(() => setPage(0), [source]);

  return (
    <>
      {isFetching || isError ? (
        isFetching ? (
          <>
            <CustomLoader />
          </>
        ) : (
          <div className="p-4">
            <p>
              Al momento non é possibile caricare il documento:{" "}
              {source?.sourceId} - {source?.type}
            </p>
          </div>
        )
      ) : (
        result && (
          <div className="h-full p-4">
            <div className="flex space-x-2 pb-2">
              <h1 className=" text-lg font-bold">{result.title}</h1>
              <a
                className="text-black font-bold underline"
                href={result.pdf.url}
                target="_blank"
                rel="noreferrer"
              >
                <PictureAsPdfOutlinedIcon />
              </a>
            </div>
            <hr></hr>
            <div className="h-[calc(100%-70px)] overflow-auto px-4 pt-2">
              {result.pdf.content[page].text}
            </div>
            <div className="flex space-x-2 justify-center pt-2">
              <button
                onClick={() => {
                  if (page > 0) {
                    setPage((page) => page - 1);
                  }
                }}
                type="button"
                className={classNames("font-bold text-cs-blue", {
                  hidden: page === 0,
                })}
              >
                <NavigateBeforeOutlinedIcon />
              </button>
              {(function Paginate() {
                const finalPage = result.pdf.content.length - 1;
                const pages = [];
                for (let i = page - 2; i <= page + 2; i++) {
                  if (i >= 0 && i <= finalPage) {
                    pages.push(
                      <button
                        className={classNames("rounded px-1", {
                          "border-2 border-cs-blue text-cs-blue font-bold":
                            i === page,
                        })}
                        onClick={() => setPage(i)}
                        type="button"
                      >
                        {i + 1}
                      </button>
                    );
                  }
                }
                if (page + 3 < finalPage) {
                  pages.push(<div className="font-bold text-cs-blue">...</div>);
                }
                if (page + 2 < finalPage) {
                  console.log(page, finalPage);
                  pages.push(
                    <button
                      className={classNames("rounded px-1", {
                        "border-2 border-cs-blue text-cs-blue font-bold":
                          page === finalPage,
                      })}
                      onClick={() => setPage(finalPage)}
                      type="button"
                    >
                      {finalPage + 1}
                    </button>
                  );
                }
                return pages;
              })()}
              <button
                onClick={() => {
                  if (page < result.pdf.content.length - 1) {
                    setPage((page) => page + 1);
                  }
                }}
                type="button"
                className={classNames("font-bold text-cs-blue", {
                  hidden: page === result.pdf.content.length - 1,
                })}
              >
                <NavigateNextOutlinedIcon />
              </button>
            </div>
          </div>
        )
      )}
    </>
  );
}
