import React, { ReactNode } from "react";
import IconWall, { IconWallTypes } from "../IconWall";
import classNames from "classnames";

export type CustomWallTypes = "loader";

export type DisabledWallWrapperTypes =
  | {
      iconWall: IconWallTypes;
      opaque?: boolean;
    }
  | {
      customWall: CustomWallTypes;
    };

export type DisabledWallWrapperProps = {
  isActive: boolean;
  variant: DisabledWallWrapperTypes;
  fullDimension: boolean;
  disabledWallWrapperClassNames?: HTMLElement["className"];
  children?: ReactNode;
};

const customWalls: Record<CustomWallTypes, () => React.ReactElement> = {
  loader: () => <> </>,
};

function DisabledWallWrapper(props: DisabledWallWrapperProps) {
  const { isActive, variant, children, fullDimension, disabledWallWrapperClassNames } = props;
  return (
    <div
      className={classNames({
        disabledWallWrapper: true,
        fullDimension: fullDimension,
        [disabledWallWrapperClassNames ?? ""] : disabledWallWrapperClassNames !== undefined,
      })}
    >
      <div className="disabledWallChildren">{children}</div>
      {isActive ? (
        <div className="disabledWallCover">
          {"iconWall" in variant ? (
            <IconWall type={variant.iconWall} opaque={variant.opaque ?? true} />
          ) : (
            customWalls[variant.customWall]()
          )}
        </div>
      ) : (
        <> </>
      )}
    </div>
  );
}

DisabledWallWrapper.defaultProps = {
  children: "",
};

export default DisabledWallWrapper;
