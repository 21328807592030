import { useEffect, useRef } from "react";

/**
 * A custom hook based on useRef that can be used to optimize re-renders.
 *
 * A possible use case is storing callbacks instead of using them directly in the dependencies arrays
 * of useCallbacks/useEffect/etc. is that they usually don't need to be called immediately, therefore
 * it's not necessary to trigger hooks when they change.
 *
 * Example:
 *
 * The following code will cause useCallback to regenerate the handleClick
 * function reference every time onClick changes, which could mean at every
 * re-render of the parent component if the parent is passing an inline function.
 * tsx
 * function MyComponent({onClick}) {
 * 	const handleClick = useCallback(() => {
 * 		console.log('clicked');
 * 		onClick();
 * 	}, [onClick]);
 *
 * 	return <button onClick={handleClick}>click me</button>
 * }
 *
 * The above code could be rewritten as follows:
 * tsx
 * function MyComponent({onClick}) {
 * 	const onClickRef = useUpdatedRef(onClick);
 * 	const handleClick = useCallback(() => {
 * 		console.log('clicked');
 * 		onClickRef.current();
 * 	}, [callbacksRef]);
 *
 * 	return <button onClick={handleClick}>click me</button>
 * }
 *
 * Note that in the second snipped the onClickRef object will not change, only its content will, therefore making useCallback
 * only run once, when MyComponent gets mounted.
 *
 * @param observedProps something that could change between re-renders (a function, a subset of props, a variable, etc.).
 * @returns a MutableRefObject having the latest values of observedProps in its `.current` property.
 */
export function useUpdatedRef<T>(observedProps: T): React.MutableRefObject<T> {
  const ref = useRef(observedProps);
  useEffect(() => {
    ref.current = observedProps;
  }, [observedProps]);

  return ref;
}
