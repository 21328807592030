import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CloudCircleOutlinedIcon from "@mui/icons-material/CloudCircleOutlined";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import { useTranslation } from "react-i18next";
import { CustomLoader } from "../CustomLoader";

export type IconWallTypes = "noData" | "calculatingData" | "loadingData";

export type IconWallProps = {
  type: IconWallTypes;
  opaque: boolean;
};

function IconWall(props: IconWallProps) {
  const { t } = useTranslation();
  const { type, opaque } = props;
  return (
    <Box
      className={`IconWall-${type} ${opaque ? "opaque" : ""}`}
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {type === "loadingData" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CustomLoader />
          <Typography component="h4" sx={{ textAlign: "center" }}>
            {t("Loading data")}
          </Typography>
        </div>
      ) : (
        <> </>
      )}
      {type === "noData" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <DesktopAccessDisabledIcon
            sx={{ fontSize: "120px", margin: "0 auto" }}
          />
          <Typography component="h4" sx={{ textAlign: "center" }}>
            {t("No data")}
          </Typography>
          <Typography component="p" sx={{ textAlign: "center" }}>
            {t("No data")}
          </Typography>
        </div>
      ) : (
        <> </>
      )}
      {type === "calculatingData" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CloudCircleOutlinedIcon
            sx={{ fontSize: "120px", margin: "0 auto" }}
          />
          <Typography component="h4" sx={{ textAlign: "center" }}>
            {t("Calculating Data")}
          </Typography>
          <Typography component="p" sx={{ textAlign: "center" }}>
            {t("Calculating Data")}
          </Typography>
        </div>
      ) : (
        <> </>
      )}
    </Box>
  );
}

export default IconWall;
