import { createTheme, ThemeProvider } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useMemo } from "react";
import "./App.css";
import ContextMenuProvider from "./components/ContextMenu/ContexMenuProvider";
import { useEventBus } from "./event-bus";
import { PortalBoxProvider } from "./functional-areas/portal-box/context";
import { PlatformRouter } from "./router/PlatformRouter";
import { useAuthStore } from "./store/authStore";
import { useUserStore } from "./store/userStore";
import getDesignTokens from "./theme/Theme";
import AuthProvider from "./utils/Auth";
import AuthContext, { AuthContextInterface } from "./utils/Auth/context";

function App() {
  const UserData = useUserStore((state) => state.UserData);

  // Update the theme only if the mode changes
  const theme = useMemo(
    () => createTheme(getDesignTokens(UserData.themeMode)),
    [UserData.themeMode]
  );

  return (
    <AuthProvider autoLogin>
      <ThemeProvider theme={theme}>
        <PortalBoxProvider>
          <ContextMenuProvider>
            <EventBusHandler />
            <PlatformRouter />
          </ContextMenuProvider>
        </PortalBoxProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

function EventBusHandler() {
  const AuthData = useAuthStore((state) => state.AuthData);
  const { signOut } = useContext(AuthContext) as AuthContextInterface;
  const { enqueueSnackbar } = useSnackbar();
  useEventBus("unauthorized", () => {
    signOut();
    if (AuthData.status.isSignedIn) {
      enqueueSnackbar("Sessione scaduta", { variant: "error" });
    }
  });
  return <></>;
}

export default App;
