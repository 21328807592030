import classNames from "classnames";
import Icon, { IconNames } from "../Icon";

const MenuItem = ({
  icon,
  title,
  action,
  isActive,
}: {
  isActive?(): boolean;
  action?(): boolean;
  title?: string;
  icon: IconNames;
}) => (
  <button
    className={classNames({
      "bg-slate-400": isActive && isActive(),
      "bg-transparent": isActive === undefined || isActive() === false,
      "border-none rounded-md cursor-pointer p-1 hover:bg-slate-400": true
    })}
    onClick={action}
    title={title}
  >
    <Icon name={icon} sx={{ color: "#fff" }}/>
  </button>
);

export default MenuItem;
