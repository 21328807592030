import { Routes, Route, Navigate, useLocation } from "react-router";
import { useAuthStore } from "../store/authStore";
import { routesDef } from "./RoutesDef";

export function PlatformRouter() {
  const AuthData = useAuthStore((state) => state.AuthData);
  const location = useLocation();

  return (
    <Routes>
      {Object.entries(routesDef).map(([key, route]) => {
        return (() => {
          if (route.privateRoute) {
            if (route.privateRoute.permissionCheck(AuthData)) {
              return (
                <Route key={key} path={route.path} element={route.page()} />
              );
            } else {
              return (
                <Route
                  key={key}
                  path={route.path}
                  element={<Navigate to="/login" state={{ from: location }} />}
                />
              );
            }
          } else {
            if (AuthData.status.isSignedIn && key === "Login") {
              return (
                <Route
                  key={key}
                  path={route.path}
                  element={<Navigate to="/home" state={{ from: location }} />}
                />
              );
            } else {
              return (
                <Route key={key} path={route.path} element={route.page()} />
              );
            }
          }
        })();
      })}
      <Route key="default" path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
}
