import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { StackingContext } from "$root/functional-areas/global";

type PortalBoxPositions = {
  x: number;
  y: number;
};

type PortalFloatingMenuProps = {
  show: boolean;
  children: ReactNode;
  position: PortalBoxPositions;
};

export function PortalFloatingMenu({
  show,
  position,
  children,
}: PortalFloatingMenuProps): JSX.Element {
  return (
    <StackingContext.Consumer>
      {({ zIndex }) =>
        createPortal(
          <div
            className={`${!show ? "hidden" : ""} absolute`}
            style={{
              zIndex,
              top: position.y,
              left: position.x,
            }}
          >
            <StackingContext.Provider value={{ zIndex: zIndex + 1 }}>
              <div>{children}</div>
            </StackingContext.Provider>
          </div>,
          document.body
        )
      }
    </StackingContext.Consumer>
  );
}
