import { type PaletteMode, type ThemeOptions } from "@mui/material";
import { grey } from "@mui/material/colors";
import PaletteColors from "./PaletteColors";

const getDesignTokens = (mode: PaletteMode): Partial<ThemeOptions> => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: PaletteColors.AGBlue,
          divider: PaletteColors.AGBlue[500],
          background: {
            default: grey[100],
            paper: grey[100],
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: PaletteColors.AGgray,
          divider: PaletteColors.AGgray[500],
          background: {
            default: PaletteColors.AGgray[700],
            paper: PaletteColors.AGgray[700],
          },
          text: {
            primary: grey[50],
            secondary: grey[500],
          },
        }),
  },
});

export default getDesignTokens;
