import { useCallback, useState } from "react";
import { PortalBoxChildrenProps } from "../PortalBox";
import { usePortalBox } from "../context";
import { useQueryNoRefetch } from "../../../utils/Hooks/useQueryNoRefetch";
import { Tab, Tabs } from "@mui/material";
import { CustomTabPanel, a11yProps } from "../../../components/Tabs";
import { CustomLoader } from "../../../components/CustomLoader";
import { useApiGen } from "$root/api/hooks";
import { SearchApiFactory } from "$root/api/api-gen-py-2";

export function WikiBox({
  setMinimized,
  setShowPortal,
}: PortalBoxChildrenProps): JSX.Element {
  const searchApi = useApiGen(SearchApiFactory);
  const [search, setSearch] = useState<{
    term: string;
    searchId: string;
  } | null>(null);

  // Result Tabs
  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const {
    data: result,
    isFetching,
    isError,
  } = useQueryNoRefetch(["SearchWikiQuery", search], {
    enabled: Boolean(search),
    retry: false,
    queryFn: async () => {
      if (!search?.term || !search?.searchId) {
        return;
      }
      const responses = await searchApi.legalSourceSearchSearchWikiSearchPost(
        search?.term,
        search?.searchId
      );
      return responses.data;
    },
    onError(e) {
      console.warn("Not able to search in Wiki, retry later");
    },
  });

  const wikiSearch = useCallback(
    (term: string, searchId: string) => {
      setShowPortal(true);
      setMinimized(false);
      setSearch({ term, searchId });
    },
    [setMinimized, setShowPortal]
  );

  usePortalBox(
    () => ({
      areas: "wiki",
      data: {
        wikiSearch,
      },
    }),
    []
  );

  return (
    <>
      {isFetching || isError ? (
        isFetching ? (
          <>
            <CustomLoader />
          </>
        ) : (
          <div className="px-4 py-6">
            <h2 className="text-center font-bold text-lg mb-3">Attenzione</h2>
            <p className="text-base">
              Al momento non é possibile caricare il documento:{" "}
              {search?.searchId} - {search?.term}
            </p>
          </div>
        )
      ) : (
        result && (
          <div className="h-full">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  <div className="flex space-x-2 items-center">
                    <span>Dispositivo</span>
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <div className="flex space-x-2 items-center">
                    <span>Massime</span>
                  </div>
                }
                {...a11yProps(1)}
              />
            </Tabs>
            <div className="h-[calc(100%-60px)] overflow-auto px-4">
              <CustomTabPanel value={tab} index={0}>
                <div>
                  <div className="text-center pb-4">
                    <h3 className="text-base mb-1">{result.title}</h3>
                    <h4 className="text-s">{result.subtitle}</h4>
                    <p className="text-xs mb-1">{result.updateInfo}</p>
                  </div>
                  <p className="mb-4">{result.dispositivo}</p>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <div>
                  {(result.sentenze as unknown as string[]).map((el, index) => (
                    <div className="mb-4" key={`st-${index}`}>
                      <p className="mb-2">{el}</p>
                    </div>
                  ))}
                </div>
              </CustomTabPanel>
            </div>
          </div>
        )
      )}
    </>
  );
}
