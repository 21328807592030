import { LinearProgress, Collapse, Button } from "@mui/material";
import { ReactNode, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export type InfoBoxPropsT = {
  title: string | ReactNode;
  onClick?: () => void;
  subtitle?: string;
  description?: string | ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
  collapsable?: ReactNode;
  collapseDefault?: boolean;
  className?: string;
};

const InfoBox: React.FunctionComponent<InfoBoxPropsT> = ({
  title,
  subtitle,
  onClick,
  description = <></>,
  icon = <></>,
  children = <></>,
  collapsable,
  collapseDefault = false,
  className,
}) => {
  const [open, setOpen] = useState(collapseDefault);
  const isLoading = false;
  return (
    <div className={` shadow-md rounded p-4 bg-white ${className}`}>
      {isLoading ? (
        <LinearProgress sx={{ borderRadius: "4px 4px 0px 0px" }} />
      ) : (
        <>
          <div className="mb-2 flex justify-between">
            <div className="flex">
              {icon}
              <div>
                {typeof title === "string" ? (
                  <h2 className="font-bold text-lg">{title}</h2>
                ) : (
                  title
                )}
                {subtitle && <p className="italic text-xs">{subtitle}</p>}
              </div>
            </div>
            {collapsable && (
              <button className="bg-transparent" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </button>
            )}
          </div>
          <p className="text-sm">{description}</p>
          {children}
          {collapsable && <Collapse in={open}>{collapsable}</Collapse>}
          {onClick && (
            <div className="mt-4 border-gray border-t pt-4">
              <div className="flex justify-end space-x-2">
                <Button onClick={() => onClick()} variant="outlined">
                  Start
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InfoBox;
