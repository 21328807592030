import Editor from "$root/components/Editor";
import PageLayout from "$root/components/PageLayout";

const Sperimentals = () => {

  return (
    <PageLayout>
      <div className="h-[80vh] flex justify-center items-center">
        <div className="w-4/6 ">
          <Editor 
          
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default Sperimentals;
