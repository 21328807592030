import { Button } from "@mui/material";
import PageLayout from "../components/PageLayout";
import { useApiGen } from "../api/hooks";
import { useQueryNoRefetch } from "../utils/Hooks/useQueryNoRefetch";
import { Link } from "react-router-dom";
import DisabledWallWrapper from "../components/DisabledWallWrapper";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { ConversationsApiFactory } from "../api/api-gen-py-2";
import { useUserStore } from "../store/userStore";
import { format } from "date-fns";

const columns: TypeColumn[] = [
  {
    name: "lastQuery",
    header: "Query",
    defaultFlex: 6,
    render: ({ data }) => (
      <Link
        className="text-black hover:text-black hover:underline"
        to={`/evolved-search/${data.uuid}`}
      >
        {data.lastQuery}
      </Link>
    ),
  },
  {
    name: "responseNumber",
    header: "Searches",
    defaultFlex: 1,
  },
  {
    name: "date",
    header: "Date",
    defaultFlex: 1,
    render: ({ data }) => format(new Date(data.date), "dd-MM-yyyy"),
  },
  {
    name: "actions",
    header: "Actions",
    defaultFlex: 1,
    render: ({ data }) =>
      data.actions.map((action: any, i: number) => (
        <Link
          key={i}
          className="text-black hover:text-black hover:underline"
          to={`/evolved-search/${data.uuid}`}
        >
          <Button variant="outlined">{action}</Button>
        </Link>
      )),
  },
];

export const EvolvedSearchListTable: React.FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  const conversationApi = useApiGen(ConversationsApiFactory);
  const { uid } = useUserStore((state) => state.UserData);

  const {
    data: searches,
    isLoading,
    isError,
  } = useQueryNoRefetch(["SearchListQuery", uid], {
    enabled: Boolean(uid),
    queryFn: async () => {
      const searchesResponse =
        await conversationApi.conversationListConversationsListUserUuidGet(uid);
      const mappedSearches = searchesResponse.data.reverse().map((el) => ({
        id: el._id,
        lastQuery: el.search_history.at(-1)?.question ?? "",
        responseNumber: el.search_history.length,
        date: el.updated_at,
        actions: ["expand"],
        uuid: el.uuid,
      }));
      return mappedSearches;
    },
    onError(e) {
      console.log(e);
    },
  });

  return (
    <div className={className}>
      {/* {isLoading || isError ? (
        <DisabledWallWrapper
          fullDimension
          isActive
          variant={{
            iconWall: isError ? "noData" : "loadingData",
            opaque: true,
          }}
        />
      ) : ( */}
      <ReactDataGrid
        columns={columns}
        dataSource={searches ?? []}
        updateMenuPositionOnColumnsChange
        className="h-full"
        loading={isLoading || isError}
        virtualizeColumns
        limit={12}
        renderLoadMask={({ visible }) =>
          visible && (
            <DisabledWallWrapper
              disabledWallWrapperClassNames="h-full flex justify-center items-center  relative"
              fullDimension
              isActive
              variant={{
                iconWall: isError ? "noData" : "loadingData",
                opaque: true,
              }}
            />
          )
        }
      />
      {/* )} */}
    </div>
  );
};

const EvolvedSearchList: React.FunctionComponent = () => {
  return (
    <PageLayout>
      <div className="relative min-h-[calc(100vh-64px)]">
        <div className="flex flex-col">
          <div className="z-10 flex h-[60px] flex-col justify-center shadow-lg bg-white">
            <div className="flex justify-between">
              <h2 className="font-bold text-lg px-4">Evolved Search</h2>
              <div className="mr-4">
                <Link
                  className="text-black hover:text-black hover:underline"
                  to={`/evolved-search`}
                >
                  <Button variant="outlined">New Search</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="my-2">
            <div className="mb-2 px-4 py-2">
              <div className="w-full shadow-lg bg-white p-2 rounded">
                <EvolvedSearchListTable className="h-[calc(100vh-180px)]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default EvolvedSearchList;
