import { Grid, Paper } from "@mui/material";
import React from "react";
import LoginForm from "../components/LoginForm";
import PageLayout from "../components/PageLayout";
import Image from "../assets/tribunale.jpg";
import Themis from "../assets/Themis_B2.png";
import PaletteColors from "../theme/PaletteColors";
import { CustomLoader } from "../components/CustomLoader";

const Login: React.FunctionComponent = () => {
  return (
    <PageLayout>
      <>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url(${Image})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            boxShadow: "none",
            border: "none",
            minHeight: "calc(100vh - 65px)",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              maxWidth: { xs: "400px", md: "1080px" },
              margin: "0 auto",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                padding: "80px 60px",
                background: `linear-gradient(to right bottom, ${PaletteColors.AGBlue[500]}, #000f2ed1)`,
              }}
            >
              <Paper
                sx={{
                  display: "block",
                  justifyContent: "center",
                  background: `transparent`,
                  height: "300px",
                  backgroundImage: `url(${Themis})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "0px",
                  boxShadow: "none",
                  border: "none",
                  flexDirection: "column",
                }}
              />
              <h1 className="font-mono text-[#cedde8] text-center mt-4">
                THEMIS
              </h1>
              <h2 className="font-mono text-[#cedde8] text-center mt-2">
                Trust, Reliability, Transparency
              </h2>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  backgroundSize: "cover",
                  borderRadius: "0px",
                  boxShadow: "none",
                  border: "none",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "none",
                    borderRadius: "0px",
                    boxShadow: "none",
                    border: "none",
                    flexDirection: "colum",
                  }}
                >
                  <LoginForm />
                </Paper>
              </Paper>
            </Grid>
          </Grid>
          <div className="absolute bottom-2 w-full">
            <p className="font-mono text-[#cedde8] text-center">
              Copyright 2012 - 2023 | All Rights Reserved
            </p>
          </div>
        </Paper>
      </>
    </PageLayout>
  );
};

export default Login;
