import {
	type DefinedUseQueryResult,
	parseQueryArgs,
	type QueryFunction,
	type QueryKey,
	useQuery,
	type UseQueryOptions,
	type UseQueryResult,
} from '@tanstack/react-query';

export const noRefetchDefaults = {
	cacheTime: 0,
	refetchInterval: false,
	refetchIntervalInBackground: false,
	refetchOnReconnect: false,
	refetchOnWindowFocus: false,
	refetchOnMount: true,
} as const;

// Creating an overload for all parameters combinations.

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
		initialData?: () => undefined;
	},
): UseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
		initialData: TQueryFnData | (() => TQueryFnData);
	},
): DefinedUseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'initialData'> & {
		initialData?: () => undefined;
	},
): UseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'initialData'> & {
		initialData: TQueryFnData | (() => TQueryFnData);
	},
): DefinedUseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey'>,
): UseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
		initialData?: () => undefined;
	},
): UseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
		initialData: TQueryFnData | (() => TQueryFnData);
	},
): DefinedUseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError>;

/**
 * Same as useQuery, but automatically refetch only on component mount.
 */
export function useQueryNoRefetch<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
	arg1: TQueryKey | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	arg2?: QueryFunction<TQueryFnData, TQueryKey> | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	arg3?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
	const parsedOptions = parseQueryArgs(arg1, arg2, arg3);
	return useQuery({
		...noRefetchDefaults,
		// Overriding defaults
		...parsedOptions,
	});
}
