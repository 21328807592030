/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdeDocumentModelFull
 */
export interface AdeDocumentModelFull {
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelFull
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelFull
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelFull
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelFull
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelFull
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof AdeDocumentModelFull
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof AdeDocumentModelFull
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof AdeDocumentModelFull
     */
    'day': number;
    /**
     * 
     * @type {PdfModel}
     * @memberof AdeDocumentModelFull
     */
    'pdf': PdfModel;
}
/**
 * 
 * @export
 * @interface AdeDocumentModelPreview
 */
export interface AdeDocumentModelPreview {
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelPreview
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelPreview
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelPreview
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelPreview
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AdeDocumentModelPreview
     */
    'link_url': string;
}
/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'abstract': string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof Answer
     */
    'sources': Array<Source>;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'uuid'?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationModel
 */
export interface ConversationModel {
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'uuid': string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'user_uuid': string;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ConversationModel
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<QuestionAndAnswer>}
     * @memberof ConversationModel
     */
    'search_history': Array<QuestionAndAnswer>;
}
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {number}
     * @memberof Feedback
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FunctionalityEnum = {
    Basic: 'basic',
    Advanced: 'advanced'
} as const;

export type FunctionalityEnum = typeof FunctionalityEnum[keyof typeof FunctionalityEnum];


/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface PdfContentModel
 */
export interface PdfContentModel {
    /**
     * 
     * @type {number}
     * @memberof PdfContentModel
     */
    'page': number;
    /**
     * 
     * @type {string}
     * @memberof PdfContentModel
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface PdfModel
 */
export interface PdfModel {
    /**
     * 
     * @type {string}
     * @memberof PdfModel
     */
    'url': string;
    /**
     * 
     * @type {Array<PdfContentModel>}
     * @memberof PdfModel
     */
    'content': Array<PdfContentModel>;
}
/**
 * 
 * @export
 * @interface QuestionAndAnswer
 */
export interface QuestionAndAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAndAnswer
     */
    'question': string;
    /**
     * 
     * @type {SearchType}
     * @memberof QuestionAndAnswer
     */
    'search_type'?: SearchType;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionAndAnswer
     */
    'question_context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAndAnswer
     */
    'abstract': string;
    /**
     * 
     * @type {Feedback}
     * @memberof QuestionAndAnswer
     */
    'feedback'?: Feedback | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAndAnswer
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAndAnswer
     */
    'date': string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof QuestionAndAnswer
     */
    'sources': Array<Source>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    Root: 'root',
    Admin: 'admin',
    User: 'user'
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const SearchType = {
    Evolved: 'Evolved',
    Similarity: 'Similarity'
} as const;

export type SearchType = typeof SearchType[keyof typeof SearchType];


/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'collection'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    'art_number'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'doc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    'score': number;
    /**
     * 
     * @type {Feedback}
     * @memberof Source
     */
    'feedback'?: Feedback | null;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token_type': string;
}
/**
 * 
 * @export
 * @interface UpdateFeedbackModel
 */
export interface UpdateFeedbackModel {
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedbackModel
     */
    'value': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackModel
     */
    'text': string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserModel
 */
export interface UpdateUserModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserModel
     */
    'username': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserModel
     */
    'email': string | null;
    /**
     * 
     * @type {RoleEnum}
     * @memberof UpdateUserModel
     */
    'role': RoleEnum | null;
    /**
     * 
     * @type {FunctionalityEnum}
     * @memberof UpdateUserModel
     */
    'functionality': FunctionalityEnum | null;
}


/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'uuid': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'hashed_password'?: string | null;
    /**
     * 
     * @type {RoleEnum}
     * @memberof UserModel
     */
    'role': RoleEnum;
    /**
     * 
     * @type {FunctionalityEnum}
     * @memberof UserModel
     */
    'functionality': FunctionalityEnum;
}


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface WikiArticle
 */
export interface WikiArticle {
    /**
     * 
     * @type {string}
     * @memberof WikiArticle
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof WikiArticle
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof WikiArticle
     */
    'updateInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WikiArticle
     */
    'dispositivo': string;
    /**
     * 
     * @type {}
     * @memberof WikiArticle
     */
    'sentenze'?:  | null;
}

/**
 * AdeDocsApi - axios parameter creator
 * @export
 */
export const AdeDocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List Documents Circolari
         * @param {number} [nDocs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet: async (nDocs?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ade_documents/ade_documents/circolari`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (nDocs !== undefined) {
                localVarQueryParameter['n_docs'] = nDocs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Documents Risoluzioni
         * @param {number} [nDocs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet: async (nDocs?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ade_documents/ade_documents/risoluzioni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (nDocs !== undefined) {
                localVarQueryParameter['n_docs'] = nDocs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show Doc Circolare
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDocCircolareAdeDocumentsCircolariDocIdGet: async (docId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('showDocCircolareAdeDocumentsCircolariDocIdGet', 'docId', docId)
            const localVarPath = `/ade_documents/circolari/{doc_id}`
                .replace(`{${"doc_id"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show Doc Circolare
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDocCircolareAdeDocumentsRisoluzioniDocIdGet: async (docId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('showDocCircolareAdeDocumentsRisoluzioniDocIdGet', 'docId', docId)
            const localVarPath = `/ade_documents/risoluzioni/{doc_id}`
                .replace(`{${"doc_id"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdeDocsApi - functional programming interface
 * @export
 */
export const AdeDocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdeDocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List Documents Circolari
         * @param {number} [nDocs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet(nDocs?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdeDocumentModelPreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet(nDocs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Documents Risoluzioni
         * @param {number} [nDocs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet(nDocs?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdeDocumentModelPreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet(nDocs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show Doc Circolare
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showDocCircolareAdeDocumentsCircolariDocIdGet(docId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdeDocumentModelFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showDocCircolareAdeDocumentsCircolariDocIdGet(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show Doc Circolare
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showDocCircolareAdeDocumentsRisoluzioniDocIdGet(docId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdeDocumentModelFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showDocCircolareAdeDocumentsRisoluzioniDocIdGet(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdeDocsApi - factory interface
 * @export
 */
export const AdeDocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdeDocsApiFp(configuration)
    return {
        /**
         * 
         * @summary List Documents Circolari
         * @param {number} [nDocs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet(nDocs?: number, options?: any): AxiosPromise<Array<AdeDocumentModelPreview>> {
            return localVarFp.listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet(nDocs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Documents Risoluzioni
         * @param {number} [nDocs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet(nDocs?: number, options?: any): AxiosPromise<Array<AdeDocumentModelPreview>> {
            return localVarFp.listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet(nDocs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show Doc Circolare
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDocCircolareAdeDocumentsCircolariDocIdGet(docId: string, options?: any): AxiosPromise<AdeDocumentModelFull> {
            return localVarFp.showDocCircolareAdeDocumentsCircolariDocIdGet(docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show Doc Circolare
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showDocCircolareAdeDocumentsRisoluzioniDocIdGet(docId: string, options?: any): AxiosPromise<AdeDocumentModelFull> {
            return localVarFp.showDocCircolareAdeDocumentsRisoluzioniDocIdGet(docId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdeDocsApi - object-oriented interface
 * @export
 * @class AdeDocsApi
 * @extends {BaseAPI}
 */
export class AdeDocsApi extends BaseAPI {
    /**
     * 
     * @summary List Documents Circolari
     * @param {number} [nDocs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdeDocsApi
     */
    public listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet(nDocs?: number, options?: AxiosRequestConfig) {
        return AdeDocsApiFp(this.configuration).listDocumentsCircolariAdeDocumentsAdeDocumentsCircolariGet(nDocs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Documents Risoluzioni
     * @param {number} [nDocs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdeDocsApi
     */
    public listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet(nDocs?: number, options?: AxiosRequestConfig) {
        return AdeDocsApiFp(this.configuration).listDocumentsRisoluzioniAdeDocumentsAdeDocumentsRisoluzioniGet(nDocs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show Doc Circolare
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdeDocsApi
     */
    public showDocCircolareAdeDocumentsCircolariDocIdGet(docId: string, options?: AxiosRequestConfig) {
        return AdeDocsApiFp(this.configuration).showDocCircolareAdeDocumentsCircolariDocIdGet(docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show Doc Circolare
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdeDocsApi
     */
    public showDocCircolareAdeDocumentsRisoluzioniDocIdGet(docId: string, options?: AxiosRequestConfig) {
        return AdeDocsApiFp(this.configuration).showDocCircolareAdeDocumentsRisoluzioniDocIdGet(docId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Conversation List
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationListConversationsListUserUuidGet: async (userUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('conversationListConversationsListUserUuidGet', 'userUuid', userUuid)
            const localVarPath = `/conversations/list/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Conversation
         * @param {string} userUuid 
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationConversationsUserUuidConversationUuidDelete: async (userUuid: string, conversationUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('deleteConversationConversationsUserUuidConversationUuidDelete', 'userUuid', userUuid)
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('deleteConversationConversationsUserUuidConversationUuidDelete', 'conversationUuid', conversationUuid)
            const localVarPath = `/conversations/{user_uuid}/{conversation_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)))
                .replace(`{${"conversation_uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Question
         * @param {string} userUuid 
         * @param {string} conversationUuid 
         * @param {string} questionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete: async (userUuid: string, conversationUuid: string, questionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete', 'userUuid', userUuid)
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete', 'conversationUuid', conversationUuid)
            // verify required parameter 'questionUuid' is not null or undefined
            assertParamExists('deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete', 'questionUuid', questionUuid)
            const localVarPath = `/conversations/delete-question/{user_uuid}/{conversation_uuid}/{question_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)))
                .replace(`{${"conversation_uuid"}}`, encodeURIComponent(String(conversationUuid)))
                .replace(`{${"question_uuid"}}`, encodeURIComponent(String(questionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Conversation
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationConversationsConversationConversationUuidGet: async (conversationUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('getConversationConversationsConversationConversationUuidGet', 'conversationUuid', conversationUuid)
            const localVarPath = `/conversations/conversation/{conversation_uuid}`
                .replace(`{${"conversation_uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Question And Answer
         * @param {string} questionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionAndAnswerConversationsQuestionQuestionUuidGet: async (questionUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionUuid' is not null or undefined
            assertParamExists('getQuestionAndAnswerConversationsQuestionQuestionUuidGet', 'questionUuid', questionUuid)
            const localVarPath = `/conversations/question/{question_uuid}`
                .replace(`{${"question_uuid"}}`, encodeURIComponent(String(questionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary New Question
         * @param {string} userUuid 
         * @param {string} query 
         * @param {SearchType} searchType 
         * @param {Array<string>} requestBody 
         * @param {string | null} [conversationUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newQuestionConversationsNewQuestionUserUuidPost: async (userUuid: string, query: string, searchType: SearchType, requestBody: Array<string>, conversationUuid?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('newQuestionConversationsNewQuestionUserUuidPost', 'userUuid', userUuid)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('newQuestionConversationsNewQuestionUserUuidPost', 'query', query)
            // verify required parameter 'searchType' is not null or undefined
            assertParamExists('newQuestionConversationsNewQuestionUserUuidPost', 'searchType', searchType)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('newQuestionConversationsNewQuestionUserUuidPost', 'requestBody', requestBody)
            const localVarPath = `/conversations/new-question/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['search_type'] = searchType;
            }

            if (conversationUuid !== undefined) {
                localVarQueryParameter['conversation_uuid'] = conversationUuid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Feedback
         * @param {string} conversationUuid 
         * @param {string} questionUuid 
         * @param {UpdateFeedbackModel} updateFeedbackModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut: async (conversationUuid: string, questionUuid: string, updateFeedbackModel: UpdateFeedbackModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut', 'conversationUuid', conversationUuid)
            // verify required parameter 'questionUuid' is not null or undefined
            assertParamExists('updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut', 'questionUuid', questionUuid)
            // verify required parameter 'updateFeedbackModel' is not null or undefined
            assertParamExists('updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut', 'updateFeedbackModel', updateFeedbackModel)
            const localVarPath = `/conversations/set-feedback/{conversation_uuid}/{question_uuid}`
                .replace(`{${"conversation_uuid"}}`, encodeURIComponent(String(conversationUuid)))
                .replace(`{${"question_uuid"}}`, encodeURIComponent(String(questionUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedbackModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Conversation List
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationListConversationsListUserUuidGet(userUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationListConversationsListUserUuidGet(userUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Conversation
         * @param {string} userUuid 
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConversationConversationsUserUuidConversationUuidDelete(userUuid: string, conversationUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConversationConversationsUserUuidConversationUuidDelete(userUuid, conversationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Question
         * @param {string} userUuid 
         * @param {string} conversationUuid 
         * @param {string} questionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete(userUuid: string, conversationUuid: string, questionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete(userUuid, conversationUuid, questionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Conversation
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationConversationsConversationConversationUuidGet(conversationUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationConversationsConversationConversationUuidGet(conversationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Question And Answer
         * @param {string} questionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionAndAnswerConversationsQuestionQuestionUuidGet(questionUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAndAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionAndAnswerConversationsQuestionQuestionUuidGet(questionUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary New Question
         * @param {string} userUuid 
         * @param {string} query 
         * @param {SearchType} searchType 
         * @param {Array<string>} requestBody 
         * @param {string | null} [conversationUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newQuestionConversationsNewQuestionUserUuidPost(userUuid: string, query: string, searchType: SearchType, requestBody: Array<string>, conversationUuid?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionAndAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newQuestionConversationsNewQuestionUserUuidPost(userUuid, query, searchType, requestBody, conversationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Feedback
         * @param {string} conversationUuid 
         * @param {string} questionUuid 
         * @param {UpdateFeedbackModel} updateFeedbackModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(conversationUuid: string, questionUuid: string, updateFeedbackModel: UpdateFeedbackModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(conversationUuid, questionUuid, updateFeedbackModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Conversation List
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationListConversationsListUserUuidGet(userUuid: string, options?: any): AxiosPromise<Array<ConversationModel>> {
            return localVarFp.conversationListConversationsListUserUuidGet(userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Conversation
         * @param {string} userUuid 
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversationConversationsUserUuidConversationUuidDelete(userUuid: string, conversationUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConversationConversationsUserUuidConversationUuidDelete(userUuid, conversationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Question
         * @param {string} userUuid 
         * @param {string} conversationUuid 
         * @param {string} questionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete(userUuid: string, conversationUuid: string, questionUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete(userUuid, conversationUuid, questionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Conversation
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationConversationsConversationConversationUuidGet(conversationUuid: string, options?: any): AxiosPromise<ConversationModel> {
            return localVarFp.getConversationConversationsConversationConversationUuidGet(conversationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Question And Answer
         * @param {string} questionUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionAndAnswerConversationsQuestionQuestionUuidGet(questionUuid: string, options?: any): AxiosPromise<QuestionAndAnswer> {
            return localVarFp.getQuestionAndAnswerConversationsQuestionQuestionUuidGet(questionUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary New Question
         * @param {string} userUuid 
         * @param {string} query 
         * @param {SearchType} searchType 
         * @param {Array<string>} requestBody 
         * @param {string | null} [conversationUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newQuestionConversationsNewQuestionUserUuidPost(userUuid: string, query: string, searchType: SearchType, requestBody: Array<string>, conversationUuid?: string | null, options?: any): AxiosPromise<QuestionAndAnswer> {
            return localVarFp.newQuestionConversationsNewQuestionUserUuidPost(userUuid, query, searchType, requestBody, conversationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Feedback
         * @param {string} conversationUuid 
         * @param {string} questionUuid 
         * @param {UpdateFeedbackModel} updateFeedbackModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(conversationUuid: string, questionUuid: string, updateFeedbackModel: UpdateFeedbackModel, options?: any): AxiosPromise<Feedback> {
            return localVarFp.updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(conversationUuid, questionUuid, updateFeedbackModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     * 
     * @summary Conversation List
     * @param {string} userUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationListConversationsListUserUuidGet(userUuid: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).conversationListConversationsListUserUuidGet(userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Conversation
     * @param {string} userUuid 
     * @param {string} conversationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public deleteConversationConversationsUserUuidConversationUuidDelete(userUuid: string, conversationUuid: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).deleteConversationConversationsUserUuidConversationUuidDelete(userUuid, conversationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Question
     * @param {string} userUuid 
     * @param {string} conversationUuid 
     * @param {string} questionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete(userUuid: string, conversationUuid: string, questionUuid: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).deleteQuestionConversationsDeleteQuestionUserUuidConversationUuidQuestionUuidDelete(userUuid, conversationUuid, questionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Conversation
     * @param {string} conversationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getConversationConversationsConversationConversationUuidGet(conversationUuid: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getConversationConversationsConversationConversationUuidGet(conversationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Question And Answer
     * @param {string} questionUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public getQuestionAndAnswerConversationsQuestionQuestionUuidGet(questionUuid: string, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).getQuestionAndAnswerConversationsQuestionQuestionUuidGet(questionUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary New Question
     * @param {string} userUuid 
     * @param {string} query 
     * @param {SearchType} searchType 
     * @param {Array<string>} requestBody 
     * @param {string | null} [conversationUuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public newQuestionConversationsNewQuestionUserUuidPost(userUuid: string, query: string, searchType: SearchType, requestBody: Array<string>, conversationUuid?: string | null, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).newQuestionConversationsNewQuestionUserUuidPost(userUuid, query, searchType, requestBody, conversationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Feedback
     * @param {string} conversationUuid 
     * @param {string} questionUuid 
     * @param {UpdateFeedbackModel} updateFeedbackModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(conversationUuid: string, questionUuid: string, updateFeedbackModel: UpdateFeedbackModel, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(conversationUuid, questionUuid, updateFeedbackModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexIndexGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexIndexGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexIndexGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexIndexGet(options?: any): AxiosPromise<string> {
            return localVarFp.indexIndexGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public indexIndexGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).indexIndexGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Chatgpt Wrapper
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGPTWrapperSearchChatgptWrapperPost: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('chatGPTWrapperSearchChatgptWrapperPost', 'query', query)
            const localVarPath = `/search/chatgptWrapper`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Legal Source Search
         * @param {string} text 
         * @param {string} qaUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legalSourceSearchSearchWikiSearchPost: async (text: string, qaUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('legalSourceSearchSearchWikiSearchPost', 'text', text)
            // verify required parameter 'qaUuid' is not null or undefined
            assertParamExists('legalSourceSearchSearchWikiSearchPost', 'qaUuid', qaUuid)
            const localVarPath = `/search/wikiSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (qaUuid !== undefined) {
                localVarQueryParameter['qa_uuid'] = qaUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Py Saul Goodman Hotline
         * @param {string} query 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost: async (query: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost', 'query', query)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost', 'requestBody', requestBody)
            const localVarPath = `/search/pySaulGoodmanHotline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Similarity Search
         * @param {string} query 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similaritySearchSearchSimilaritySearchPost: async (query: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('similaritySearchSearchSimilaritySearchPost', 'query', query)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('similaritySearchSearchSimilaritySearchPost', 'requestBody', requestBody)
            const localVarPath = `/search/similaritySearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stub Call
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stubCallSearchStubCallPost: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('stubCallSearchStubCallPost', 'query', query)
            const localVarPath = `/search/stubCall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Chatgpt Wrapper
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatGPTWrapperSearchChatgptWrapperPost(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatGPTWrapperSearchChatgptWrapperPost(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Legal Source Search
         * @param {string} text 
         * @param {string} qaUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async legalSourceSearchSearchWikiSearchPost(text: string, qaUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WikiArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.legalSourceSearchSearchWikiSearchPost(text, qaUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Py Saul Goodman Hotline
         * @param {string} query 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost(query: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost(query, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Similarity Search
         * @param {string} query 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async similaritySearchSearchSimilaritySearchPost(query: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.similaritySearchSearchSimilaritySearchPost(query, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stub Call
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stubCallSearchStubCallPost(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stubCallSearchStubCallPost(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Chatgpt Wrapper
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGPTWrapperSearchChatgptWrapperPost(query: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatGPTWrapperSearchChatgptWrapperPost(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Legal Source Search
         * @param {string} text 
         * @param {string} qaUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legalSourceSearchSearchWikiSearchPost(text: string, qaUuid: string, options?: any): AxiosPromise<WikiArticle> {
            return localVarFp.legalSourceSearchSearchWikiSearchPost(text, qaUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Py Saul Goodman Hotline
         * @param {string} query 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost(query: string, requestBody: Array<string>, options?: any): AxiosPromise<Answer> {
            return localVarFp.pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost(query, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Similarity Search
         * @param {string} query 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        similaritySearchSearchSimilaritySearchPost(query: string, requestBody: Array<string>, options?: any): AxiosPromise<Answer> {
            return localVarFp.similaritySearchSearchSimilaritySearchPost(query, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stub Call
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stubCallSearchStubCallPost(query: string, options?: any): AxiosPromise<void> {
            return localVarFp.stubCallSearchStubCallPost(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Chatgpt Wrapper
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public chatGPTWrapperSearchChatgptWrapperPost(query: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).chatGPTWrapperSearchChatgptWrapperPost(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Legal Source Search
     * @param {string} text 
     * @param {string} qaUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public legalSourceSearchSearchWikiSearchPost(text: string, qaUuid: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).legalSourceSearchSearchWikiSearchPost(text, qaUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Py Saul Goodman Hotline
     * @param {string} query 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost(query: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).pySaulGoodmanHotlineSearchPySaulGoodmanHotlinePost(query, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Similarity Search
     * @param {string} query 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public similaritySearchSearchSimilaritySearchPost(query: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).similaritySearchSearchSimilaritySearchPost(query, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stub Call
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public stubCallSearchStubCallPost(query: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).stubCallSearchStubCallPost(query, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost: async (userModel: UserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userModel' is not null or undefined
            assertParamExists('createUserUsersPost', 'userModel', userModel)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersUuidDelete: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteUserUsersUuidDelete', 'uuid', uuid)
            const localVarPath = `/users/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login For Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginForAccessTokenUsersTokenPost: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginForAccessTokenUsersTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginForAccessTokenUsersTokenPost', 'password', password)
            const localVarPath = `/users/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersMeUsersUsersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserUsersUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {string} uuid 
         * @param {UpdateUserModel} updateUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersUuidPut: async (uuid: string, updateUserModel: UpdateUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('updateUserUsersUuidPut', 'uuid', uuid)
            // verify required parameter 'updateUserModel' is not null or undefined
            assertParamExists('updateUserUsersUuidPut', 'updateUserModel', updateUserModel)
            const localVarPath = `/users/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserUsersPost(userModel: UserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUsersPost(userModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete User
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserUsersUuidDelete(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserUsersUuidDelete(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login For Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginForAccessTokenUsersTokenPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginForAccessTokenUsersTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersMeUsersUsersMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersMeUsersUsersMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUserUsersUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showUserUsersUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {string} uuid 
         * @param {UpdateUserModel} updateUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserUsersUuidPut(uuid: string, updateUserModel: UpdateUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserUsersUuidPut(uuid, updateUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {UserModel} userModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost(userModel: UserModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.createUserUsersPost(userModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersUuidDelete(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserUsersUuidDelete(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersUsersGet(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.listUsersUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login For Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginForAccessTokenUsersTokenPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: any): AxiosPromise<Token> {
            return localVarFp.loginForAccessTokenUsersTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersMeUsersUsersMeGet(options?: any): AxiosPromise<UserModel> {
            return localVarFp.readUsersMeUsersUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserUsersUserGet(options?: any): AxiosPromise<UserModel> {
            return localVarFp.showUserUsersUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {string} uuid 
         * @param {UpdateUserModel} updateUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersUuidPut(uuid: string, updateUserModel: UpdateUserModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.updateUserUsersUuidPut(uuid, updateUserModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Create User
     * @param {UserModel} userModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserUsersPost(userModel: UserModel, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserUsersPost(userModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserUsersUuidDelete(uuid: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserUsersUuidDelete(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsersUsersGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUsersUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login For Access Token
     * @param {string} username 
     * @param {string} password 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public loginForAccessTokenUsersTokenPost(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).loginForAccessTokenUsersTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUsersMeUsersUsersMeGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUsersMeUsersUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public showUserUsersUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).showUserUsersUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {string} uuid 
     * @param {UpdateUserModel} updateUserModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserUsersUuidPut(uuid: string, updateUserModel: UpdateUserModel, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserUsersUuidPut(uuid, updateUserModel, options).then((request) => request(this.axios, this.basePath));
    }
}



