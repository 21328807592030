import { ReactNode } from "react";

type HoverProps = {
  children: ReactNode;
  elementHover: ReactNode;
  className?: HTMLDivElement["className"];
};

/**
 * HOVER COMPONENT (SERVER)
 *
 * Example:
 * ```tsx
 * function MyComponent() {
 * 	return (
 *      <Hover elementHover={<>Hover Me</>} className="flex">
 *          Hover Content
 *      </Hover>;
 *    )
 * }
 * ```
 *
 * @param children
 * @param elementHover
 * @param className
 *
 */

export default function Hover({
  children,
  elementHover,
  className,
}: HoverProps) {
  return (
    <div className={`group ${className ?? ""}`}>
      <div>{elementHover}</div>
      <div className="hidden group-hover:block relative">{children}</div>
    </div>
  );
}
