import * as Icons from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
export type IconNames = keyof typeof Icons;

const Icon = (props: { name: IconNames } & SvgIconProps) => {
  const { name, ...rest } = props;
  const Icon = Icons[name];
  return <Icon {...rest} />;
};

export default Icon;