import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface IAuth {
  JWTToken: null | string;
  refreshToken: null | string;
  status: {
    isSignedIn: boolean;
  };
}

export interface IAuthStore {
  AuthData: IAuth;
  updateAuth: (value: Partial<IAuth>) => void;
  removeAuth: () => void;
}

const defaultAuth: IAuth = {
  JWTToken: null,
  refreshToken: null,
  status: {
    isSignedIn: false,
  },
};

export const useAuthStore = create<IAuthStore>()(
  devtools(
    persist(
      (set) => ({
        AuthData: defaultAuth,
        updateAuth: (value) => {
          set((state) => ({
            AuthData: {
              ...state.AuthData,
              ...value,
            },
          }));
        },
        removeAuth: () => {
          set({ AuthData: defaultAuth });
        },
      }),
      { name: "Auth Store" }
    ),
    { enabled: true }
  )
);
