import { ReactNode, useContext, useEffect, useRef } from "react";
import { ContextMenuContext } from "./ContexMenuProvider";

type ContexMenuWrapperProps = {
  children: ReactNode;
  text?: string;
};

export default function ContextMenuWrapper({
  children,
  text,
}: ContexMenuWrapperProps) {
  const ctx = useContext(ContextMenuContext);
  const containerRef = useRef<HTMLInputElement | null>(null);

  ctx?.setText(text);

  useEffect(() => {
    const MyRef = containerRef.current;

    const handleClick = (ev: MouseEvent) => {
      ev.preventDefault();
      ctx?.setPoints({
        x: ev.screenX,
        y: ev.screenY - 140,
      });
      ctx?.setClicked(true);
    };

    if (!MyRef) {
      return;
    }

    MyRef.addEventListener("contextmenu", (e) => handleClick(e));

    return () => {
      MyRef.removeEventListener("contextmenu", (e) => handleClick(e));
    };
  }, [ctx]);

  return (
    <>
      <div ref={containerRef} className="relative">
        <div className="relative">{children}</div>
      </div>
    </>
  );
}
