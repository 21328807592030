import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { useContext, useEffect, useState } from "react";
import { PortalBoxContext } from "../../functional-areas/portal-box/context";

type MenuBoxProps = {
  selectedContent?: string;
  noSelection?: boolean;
};

type CopyToClipboardProps =
  | { text: string; type: "section" }
  | { type: "selection" };

export function MenuBox({
  selectedContent,
  noSelection = false,
}: MenuBoxProps) {
  const { state } = useContext(PortalBoxContext);
  const [copied, setCopied] = useState(false);
  const [copiedSelection, setCopiedSelection] = useState(false);
  const WSelection = (window.getSelection() ?? "").toString();

  const copyToClipboard = async (props: CopyToClipboardProps) => {
    const isSection = props.type === "section";
    try {
      //IMPORTANT => avigator.clipboard.writeText work only with https!
      await navigator.clipboard.writeText(isSection ? props.text : WSelection);
      isSection ? setCopied(true) : setCopiedSelection(true);
    } catch (error) {
      //FIXME show an error in UI for feedback
      console.log("Error copying to clipboard:", error);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setCopied(false);
    }, 600);

    return () => {
      clearTimeout(timeId);
    };
  }, [copied]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setCopiedSelection(false);
    }, 600);

    return () => {
      clearTimeout(timeId);
    };
  }, [copiedSelection]);

  return (
    <div className="w-[250px] border rounded-md shadow-md p-3 grid gap-3 bg-white text-gray-600">
      {WSelection && !noSelection && (
        <div className="flex justify-between w-full space-x-3">
          <button
            onClick={() => {
              void copyToClipboard({ type: "selection" });
            }}
            type="button"
            className={`flex space-x-3 bg-transparent grow justify-start text-left ${
              copiedSelection ? "font-bold" : ""
            }`}
          >
            <ContentCopyIcon fontSize="small" />
            <span>{copiedSelection ? "Copied!" : "Copy"}</span>
          </button>
          <NavigateNextIcon />
        </div>
      )}
      {selectedContent && (
        <div className="flex justify-between w-full space-x-3">
          <button
            onClick={() => {
              void copyToClipboard({
                type: "section",
                text: selectedContent,
              });
            }}
            type="button"
            className={`flex space-x-3 bg-transparent grow justify-start text-left ${
              copied ? "font-bold" : ""
            }`}
          >
            <ContentCopyIcon fontSize="small" />
            <span>{copied ? "Copied!" : "Copy Section"}</span>
          </button>
          <NavigateNextIcon />
        </div>
      )}
      {WSelection && !noSelection && selectedContent && <hr></hr>}
      {WSelection && !noSelection && (
        <div className="flex justify-between w-full space-x-3">
          <button
            type="button"
            className="flex space-x-3 bg-transparent grow justify-start text-left"
          >
            <BookmarkAddOutlinedIcon fontSize="small" />
            <span>Send to Notes</span>
          </button>
          <NavigateNextIcon />
        </div>
      )}

      {selectedContent && (
        <div className="flex justify-between w-full space-x-3">
          <button
            type="button"
            className="flex space-x-3 bg-transparent grow justify-start text-left"
          >
            <BookmarkAddOutlinedIcon fontSize="small" />
            <span>Send Section to Notes</span>
          </button>
          <NavigateNextIcon />
        </div>
      )}

      {((WSelection && !noSelection) || selectedContent) && <hr></hr>}

      <div className="flex justify-between w-full space-x-3">
        <button
          onClick={() => console.warn("not active")}
          disabled
          type="button"
          className="flex space-x-3 bg-transparent grow justify-start text-left"
        >
          <PlagiarismOutlinedIcon fontSize="small" />
          <span>Open Wiki</span>
        </button>
        <NavigateNextIcon />
      </div>
    </div>
  );
}
