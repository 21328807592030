import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import css from "./MarkdownRenderer.module.scss";
import { memo } from "react";

export type MarkdownRendererProps = {
  children: string;
  componentOverrides?: Partial<Partial<Components>>;
};

export const MarkdownRenderer = memo(
  _MarkdownRenderer
) as typeof _MarkdownRenderer;

function _MarkdownRenderer({
  children,
  componentOverrides,
}: MarkdownRendererProps): JSX.Element {
  return (
    <ReactMarkdown
      className={css.markdown}
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ node: _node, ...props }) => (
          <h1 className="text-[16px] font-bold pb-2" {...props}>
            {props.children}
          </h1>
        ),
        h2: ({ node: _node, ...props }) => (
          <h2 className="text-[14px] font-bold pb-2 pt-2" {...props}>
            {props.children}
          </h2>
        ),
        h3: ({ node: _node, ...props }) => (
          <h3 className="text-[12px] font-bold pb-2 pt-2" {...props}>
            {props.children}
          </h3>
        ),
        ul: ({ node: _node, ...props }) => (
          <ul className="list-disc pl-4" {...props} />
        ),
        ol: ({ node: _node, ...props }) => (
          <ul className="list-decimal pl-4" {...props} />
        ),
        li: ({ node: _node, ...props }) => <li {...props} />,
        p: ({ node: _node, ...props }) => (
          <p className="text pb-2" {...props} />
        ),
        blockquote: ({ node: _node, ...props }) => (
          <blockquote className="bg-sky-100 px-2 py-1 rounded" {...props} />
        ),
        table: ({ node: _node, ...props }) => <table {...props} />,
        code: ({ node: _node, ...props }) => <span {...props} />,
        pre: ({ node: _node, ...props }) => <i {...props} />,
        ...componentOverrides,
      }}
    >
      {children}
    </ReactMarkdown>
  );
}
