import { RouteProps } from "react-router";
import { IUser } from "../store/userStore";
import { IAuth } from "../store/authStore";
import EvolvedSearch from "../pages/EvolvedSearch";
import Login from "../pages/Login";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import EvolvedSearchList from "../pages/EvolvedSearchList";
import Home from "../pages/Home";
import Sperimentals from "$root/pages/Sperimentals";
import { Science } from "@mui/icons-material";

export type RouteDefProps = {
  page: () => JSX.Element;
  path: string;
  query?: string;
  privateRoute?: {
    permissionCheck: (auth: IAuth) => boolean;
  };
  sideBarProps?: {
    label: string;
    icon?: JSX.Element;
    hide?: boolean | ((user: IUser) => boolean);
    subMenuItems?: {
      label: string;
      path: string;
      query?: string;
      icon?: JSX.Element;
    }[];
  };
} & RouteProps;

export const routesDef: { [key: string]: RouteDefProps } = {
  Login: {
    page: () => <Login />,
    path: "/login",
  },
  Dashboard: {
    page: () => <Home />,
    path: "/home",
    privateRoute: {
      permissionCheck: (auth: IAuth) => auth.status.isSignedIn,
    },
    sideBarProps: {
      label: "Home",
      icon: <HomeOutlinedIcon />,
    },
  },
  EvolvedSearch: {
    page: () => <EvolvedSearchList />,
    path: "/evolved-search-list",
    privateRoute: {
      permissionCheck: (auth: IAuth) => auth.status.isSignedIn,
    },
    sideBarProps: {
      label: "Evolved Search",
      icon: <TroubleshootIcon />,
      subMenuItems: [
        { label: "New Search", path: "/evolved-search" },
        { label: "My Searches", path: "/evolved-search-list" },
      ],
    },
  },
  EvolvedSearchChat: {
    page: () => <EvolvedSearch />,
    path: "/evolved-search/:searchId",
    privateRoute: {
      permissionCheck: (auth: IAuth) => auth.status.isSignedIn,
    },
  },
  EvolvedSearchChatNew: {
    page: () => <EvolvedSearch />,
    path: "/evolved-search",
    privateRoute: {
      permissionCheck: (auth: IAuth) => auth.status.isSignedIn,
    },
  },
  Sperimentals: {
    page: () => <Sperimentals />,
    path: "/sperimentals",
    sideBarProps: {
      label: "Sperimentals",
      icon: <Science />,
    },
    privateRoute: {
      permissionCheck: (auth: IAuth) => auth.status.isSignedIn,
    },
  },
};
