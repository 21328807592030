import { ReactNode } from "react";

export type NodeOrFn<Props = undefined> =
  | ReactNode
  | ((props: Props) => ReactNode);

export function renderNodeOrFn<Child extends NodeOrFn<any>>(
  nodeOrFn: Child,
  props: Child extends (props: infer Props) => ReactNode ? Props : undefined
): ReactNode {
  return typeof nodeOrFn === "function" ? nodeOrFn(props) : nodeOrFn;
}
