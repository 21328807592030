import { Button, Rating, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import EvModal from "../../components/Modal";

type FeedbackEvaluation = {
  text?: string | null;
  value: number | null;
};

type FeedbackProps = {
  mode: "Modal" | "Plain";
  currentFeedback?: FeedbackEvaluation | null;
  editable?: boolean;
  onChange?: (value: FeedbackEvaluation) => void;
  onSave?: (value: FeedbackEvaluation) => void;
};

function FeedbackBox({
  mode,
  currentFeedback,
  editable = true,
  onChange,
  onSave,
}: FeedbackProps): JSX.Element {
  const [evaluation, setEvaluation] = useState<FeedbackEvaluation>({
    text: undefined,
    value: null,
  });
  const isModal = mode === "Modal";

  const updateFeedback = useCallback(() => {
    if (currentFeedback) {
      setEvaluation(currentFeedback);
    } else {
      setEvaluation({
        text: undefined,
        value: null,
      });
    }
  }, [currentFeedback]);

  useEffect(() => {
    updateFeedback();
  }, [updateFeedback]);

  return isModal ? (
    <EvModal
      title="Extended feedback"
      description={"Set an extended feedback"}
      activator={
        <Button
          type="button"
          onClick={() => updateFeedback()}
          variant="outlined"
          size="small"
        >
          Feedback
        </Button>
      }
    >
      <>
        <Typography component="h4" sx={{ textAlign: "left", marginTop: 1 }}>
          Rating
        </Typography>

        <Rating
          onChange={(e, value) => {
            setEvaluation({ ...evaluation, value: value });
            onChange?.({ ...evaluation, value: value });
          }}
          value={evaluation?.value}
        />
        <Typography component="h4" sx={{ textAlign: "left", marginTop: 1 }}>
          Notes
        </Typography>
        <TextField
          fullWidth
          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          onChange={(e) => {
            setEvaluation({ ...evaluation, text: e.target.value });
            onChange?.({ ...evaluation, text: e.target.value });
          }}
          value={evaluation?.text}
        />
        {onSave && (
          <div className="mt-4">
            <Button
              type="button"
              variant="outlined"
              onClick={() => onSave(evaluation)}
            >
              Save
            </Button>
          </div>
        )}
      </>
    </EvModal>
  ) : (
    <>
      <Rating
        disabled={editable}
        onChange={(e, value) => {
          setEvaluation({ ...evaluation, value: value });
          onChange?.({ ...evaluation, value: value });
          onSave?.({ ...evaluation, value: value });
        }}
        value={evaluation?.value}
      />
    </>
  );
}

export default FeedbackBox;
