import { type ReactNode, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { type IAuth, useAuthStore } from "../../store/authStore";
import { useUserStore } from "../../store/userStore";
import AuthContext, { type AuthContextInterface } from "./context";
import { useApiGen } from "../../api/hooks";
import { UsersApiFactory } from "../../api/api-gen-py-2";

interface AuthProviderProps {
  autoLogin: boolean;
  children: ReactNode;
}

function AuthProvider(props: AuthProviderProps): JSX.Element {
  const { children, autoLogin } = props;
  const userApi = useApiGen(UsersApiFactory);
  const navigate = useNavigate();

  // User Store
  const updateUser = useUserStore((state) => state.updateUser);
  const removeUser = useUserStore((state) => state.removeUser);

  // Auth Store
  const updateAuth = useAuthStore((state) => state.updateAuth);
  const removeAuth = useAuthStore((state) => state.removeAuth);

  // Clear Auth Data
  const clearAuth = useCallback((): void => {
    removeUser(); // Reset User Store
    removeAuth(); // Reset Auth Store
    localStorage.removeItem("_RToken"); // Clear Local Storage
  }, [removeAuth, removeUser]);

  const setAuth = useCallback(
    (newAuthState: Partial<IAuth>, newRefreshToken?: string): void => {
      if (newRefreshToken != null) {
        localStorage.setItem("_RToken", newRefreshToken); // Set Local Storage
      }
      updateAuth({ ...newAuthState }); // Set Zustand
    },
    [updateAuth]
  );

  // SignOut
  const signOut = useCallback(async (): Promise<void> => {
    try {
      clearAuth();
      navigate("/login", { replace: true });
    } catch (e) {
      throw new Error();
    }
  }, [clearAuth, navigate]);

  // SignIn
  const signIn = useCallback(
    async (userName: string, password: string): Promise<void> => {
      try {
        const { data: userResponse } =
          await userApi.loginForAccessTokenUsersTokenPost(userName, password);

        const authApiResponse = {
          jwt: userResponse.access_token,
        };

        const newStatus = {
          JWTToken: authApiResponse.jwt,
          refreshToken: authApiResponse.jwt,
          status: {
            isSignedIn: true,
          },
        };
        setAuth(newStatus, authApiResponse.jwt);

        // Get and Update User Data
        const { data: userData } = await userApi.showUserUsersUserGet({
          headers: {
            Authorization: "Bearer " + authApiResponse.jwt,
          },
        });

        const userApiResponse = {
          uid: userData.uuid ?? undefined,
          email: userData.email,
          username: userData.username,
          availableServices: [userData.functionality],
          roles: [userData.role],
        };
        updateUser({ ...userApiResponse });

        navigate("../", { replace: true });
      } catch (e) {
        await signOut();
        throw new Error();
      }
    },
    [navigate, setAuth, signOut, updateUser, userApi]
  );

  // Autologin
  const FAutoLogin = async (): Promise<void> => {
    if (autoLogin) {
      try {
        const oldRefreshToken = localStorage.getItem("_RToken");
        if (oldRefreshToken) {
          const newStatus = {
            JWTToken: oldRefreshToken,
            refreshToken: oldRefreshToken,
            status: {
              isSignedIn: true,
            },
          };
          setAuth(newStatus, oldRefreshToken);
          // Get and Update User Data
          const { data: userData } = await userApi.showUserUsersUserGet({
            headers: {
              Authorization: "Bearer " + oldRefreshToken,
            },
          });

          const userApiResponse = {
            uid: userData.uuid ?? undefined,
            email: userData.email,
            username: userData.username,
            availableServices: [userData.functionality],
            roles: [userData.role],
          };
          updateUser({ ...userApiResponse });
        } else {
          await signOut();
        }
      } catch (e) {
        await signOut();
        throw new Error();
      }
    }
  };

  useEffect(() => {
    void FAutoLogin();
  }, []);

  // Context Values
  const AuthAppContext: AuthContextInterface = useMemo(
    () => ({
      signIn,
      signOut,
    }),
    [signIn, signOut]
  );

  return (
    <AuthContext.Provider value={AuthAppContext}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
