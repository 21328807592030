import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxWidth: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};

export default function EvModal({
  title,
  children,
  activator,
  description,
  show = false,
  closeCustomComponent,
}: {
  title?: string;
  children?: JSX.Element;
  activator?: JSX.Element;
  description?: string;
  show?: boolean;
  closeCustomComponent?: JSX.Element;
}): JSX.Element {
  const [open, setOpen] = React.useState(show);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button
        type="button"
        onClick={(e) => {
          handleOpen();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {activator ?? "Open modal"}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {title && (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
          )}
          <div className="overflow-auto max-h-[calc(100vh_-_200px)] my-3">
            {description && (
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {description}
              </Typography>
            )}
            {children}
          </div>
          <div className="flex justify-end">
            {closeCustomComponent ? (
              <button
                type="button"
                onClick={(e) => {
                  handleClose();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {closeCustomComponent}
              </button>
            ) : (
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleClose();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Close
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
