import * as React from "react";
import { styled, type Theme, type CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import PrimaryAppBar from "./PrimaryAppBar";
import { useAuthStore } from "../store/authStore";
import { useLocation, useNavigate } from "react-router-dom";
import PaletteColors from "../theme/PaletteColors";
import { RouteDefProps, routesDef } from "../router/RoutesDef";
import { Collapse, Divider, Tab, Tabs } from "@mui/material";
import EvAutoComplete from "./Autocomplete";
import { Logout } from "@mui/icons-material";
import AuthContext, { AuthContextInterface } from "../utils/Auth/context";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { CustomTabPanel } from "./Tabs";

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: PaletteColors.AGwhite,
  borderWidth: 0,
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: PaletteColors.AGwhite,
  borderWidth: 0,
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  marginTop: "64px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...((open ?? false) && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!(open ?? false) && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function BaseMenuItem(props: {
  myKey: string;
  open: boolean;
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
}): JSX.Element {
  const { myKey, label, icon, open, onClick } = props;
  return (
    <ListItem key={myKey} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          borderRadius: open ? 2 : 0,
          ":hover": {
            backgroundColor: `${PaletteColors.AGBrown[50]}`,
            borderRight: open ? 0 : `3px solid ${PaletteColors.AGBrown[700]}`,
          },
        }}
        onClick={onClick}
      >
        {icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}

function MenuItem(props: {
  myKey: string;
  route: RouteDefProps;
  open: boolean;
}): JSX.Element {
  const { myKey, route, open } = props;
  const { sideBarProps } = route;
  const subMenuItems = sideBarProps?.subMenuItems;
  const [collapseOpen, setCollapseOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isCurrent = location.pathname.includes(route.path);

  return (
    <>
      {sideBarProps && (
        <ListItem
          key={myKey}
          disablePadding
          sx={{
            display: "block",
            backgroundColor: "transparent",
            borderRadius: 2,
            marginBottom: 1,
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              borderRight:
                isCurrent && !open
                  ? `3px solid ${PaletteColors.AGBrown[700]}`
                  : 0,
              backgroundColor: isCurrent
                ? `${PaletteColors.AGBrown[50]}`
                : "transparent",
              borderRadius: open ? 2 : 0,
              ":hover": {
                backgroundColor: `${PaletteColors.AGBrown[50]}`,
                borderRight: open
                  ? 0
                  : `3px solid ${PaletteColors.AGBrown[700]}`,
              },
            }}
            onClick={() => navigate(route.path)}
          >
            {sideBarProps.icon && (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                {sideBarProps.icon}
              </ListItemIcon>
            )}
            {open && (
              <ListItemText
                primary={sideBarProps.label}
                sx={{ opacity: open ? 1 : 0 }}
              />
            )}
            {open && subMenuItems && (
              <button
                className="bg-transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  setCollapseOpen(!collapseOpen);
                }}
              >
                {collapseOpen ? (
                  <RemoveRoundedIcon fontSize={"small"} />
                ) : (
                  <AddRoundedIcon fontSize={"small"} />
                )}
              </button>
            )}
          </ListItemButton>
          {open && subMenuItems && (
            <Collapse in={collapseOpen}>
              <List>
                {subMenuItems.map((item) => (
                  <div className=" border-l border-neutral-400 ml-7 pl-4">
                    <BaseMenuItem
                      myKey={item.label}
                      onClick={() => navigate(item.path)}
                      label={item.label}
                      open={open}
                      icon={item.icon}
                    />
                  </div>
                ))}
              </List>
            </Collapse>
          )}
        </ListItem>
      )}
    </>
  );
}

export default function PrimaryDrawer(props: {
  children: React.ReactNode;
}): JSX.Element {
  const AuthData = useAuthStore((state) => state.AuthData);
  const { isSignedIn } = AuthData.status;
  const { children } = props;
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const { signOut } = React.useContext(AuthContext) as AuthContextInterface;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDrawerChange = (): void => {
    setOpen(!open);
  };

  return isSignedIn ? (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <PrimaryAppBar handleDrawerChange={handleDrawerChange} open={open} />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Box
          sx={{
            padding: open ? "4px" : "0px",
          }}
        >
          {open ? (
            <Box sx={{ padding: "20px" }}>
              <Box
                sx={{
                  borderBottom: 0,
                  borderColor: "divider",
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="menu sections"
                >
                  <Tab label="My account" />
                  <Tab label="Settings" />
                </Tabs>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                <List>
                  {Object.entries(routesDef).map(([key, route]) => (
                    <MenuItem key={key} myKey={key} route={route} open={open} />
                  ))}
                </List>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                {/* FIXME: Add elements */}
              </CustomTabPanel>
            </Box>
          ) : (
            <List>
              {Object.entries(routesDef).map(([key, route]) => (
                <MenuItem key={key} myKey={key} route={route} open={open} />
              ))}
            </List>
          )}

          <div className="absolute w-full bottom-0">
            <div className={open ? "pl-4 pr-7 mb-2" : "mb-2"}>
              <Divider />
            </div>
            <div className={open ? "pl-4 pr-7 mb-2" : "mb-2"}>
              <BaseMenuItem
                myKey="Logout"
                onClick={signOut}
                label="Logout"
                open={open}
                icon={<Logout fontSize="small" />}
              />
            </div>
          </div>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  ) : (
    <Box sx={{ marginTop: "65px" }}>
      <CssBaseline />
      <PrimaryAppBar handleDrawerChange={handleDrawerChange} open={open} />
      <Box component="main">{children}</Box>
    </Box>
  );
}
