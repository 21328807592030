import type { AxiosInstance } from "axios";
import axios from "axios";
import { useMemo } from "react";
import { useAuthStore } from "../store/authStore";
import { eventBus } from "../event-bus";
import { Configuration } from "./api-gen-py-2";

// TODO add externally ENV
// ToDodiversificate configuration between different Api
const env = {
  clientEnv: "production",
};

type BasePathEnv = "default" | "py" | "py2";

// TODO: partial api endpoint migration
export const baseApiPathByEnv: Record<
  typeof env.clientEnv,
  Record<BasePathEnv, string>
> = {
  production: {
    default: "https://bettercallsaulgoodman.gigarent.it",
    py: "http://18.130.243.109:8000", //PY Api Base Url
    py2: "https://bettercallsaulgoodman.gigarent.it", //PY2 Api Base Url
  },
};

export const baseApiPath = baseApiPathByEnv[env.clientEnv];

export type ControllerFactory<T> = (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) => T;

const preconfiguredAxiosInstance = axios.create({
  timeout: 15 * 60 * 1000, // 15 minutes
});
preconfiguredAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      eventBus.emit("unauthorized");
    }
    return Promise.reject(error);
  }
);

/**
 * Example:
 * ```tsx
 * const portfolioApi = makeAuthController({ controllerFactory: InvestmentsControllerApiFactory });
 * ```
 */
export function makeAuthController<T>({
  controllerFactory,
  axiosInstance,
  token,
  basePathEnv = "default",
}: {
  controllerFactory: ControllerFactory<T>;
  axiosInstance?: AxiosInstance;
  token?: string;
  basePathEnv?: string;
}): T {
  return controllerFactory(
    new Configuration({
      // For some reason this doesn't work after login (until page reload),
      // but explicitly setting the header (see below) does the trick.
      // accessToken: token,
      baseOptions: !token
        ? undefined
        : {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
      basePath: baseApiPath[basePathEnv as BasePathEnv],
    }),
    undefined,
    axiosInstance ?? preconfiguredAxiosInstance
  );
}

/**
 * Example:
 * ```tsx
 * const portfolioApi = useApiGen(InvestmentsControllerApiFactory);
 * ```
 */
export function useApiGen<T>(
  controllerFactory: ControllerFactory<T>,
  basePathEnv?: string,
  axiosInstance?: AxiosInstance
): T {
  const userToken = useAuthStore.getState().AuthData.JWTToken ?? undefined;
  const api = useMemo(
    () =>
      makeAuthController({
        controllerFactory,
        axiosInstance,
        token: userToken,
        basePathEnv,
      }),
    [controllerFactory, axiosInstance, userToken, basePathEnv]
  );
  return api;
}
