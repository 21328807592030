import EventEmitter from "eventemitter3";
import { useEffect } from "react";
import type { TypedEventEmitter } from "./helper-types";
import { useUpdatedRef } from "../utils/Hooks/useUpdatedRef";

/**
 * This type defines global app events which can be emitted on the
 * event bus.
 *
 * Each key corresponds to an event name, while each corresponding type
 * corresponds to the payload for that event.
 *
 * Example:
 *
 * ```ts
 * export type EventPayloadMap = { myEvent: { myPayloadContent: string } };
 * eventBus.on("myEvent", ({ myPayloadContent }) => console.log(myPayloadContent));
 * eventBus.emit("myEvent", { myPayloadContent: "example" });
 * ```
 */
export type EventPayloadMap = {
  unauthorized: void;
};

/**
 * App event bus, used to listen and dispatch events that are not specific to a specific context, e.g.
 * expired authentication/authorization token.
 */
export const eventBus =
  new EventEmitter<EventPayloadMap>() as unknown as TypedEventEmitter<EventPayloadMap>;

/**
 * Attach to an event on the event bus.
 *
 * @param eventName name of the event.
 * @param listener a callback that will be called with the event payload.
 */
export function useEventBus<K extends keyof EventPayloadMap>(
  eventName: K,
  listener: (payload: EventPayloadMap[K]) => void
): void {
  const listenerRef = useUpdatedRef(listener);

  useEffect(() => {
    function wrappedListener(payload: EventPayloadMap[K]) {
      listenerRef.current(payload);
    }

    eventBus.on(eventName, wrappedListener);

    return () => {
      eventBus.off(eventName, wrappedListener);
    };
  }, [eventName, listenerRef]);
}
