const PaletteColors = {
  AGblack: "#333",
  AGwhite: "#fff",
  AGdarkGrey: "#121212",

  // BaseBlue
  AGBlue: {
    500: "#00102e",
  },

  AGGreenL: {
    500: "#04ffc3",
  },

  AGgray: {
    50: "#CCCCCC",
    100: "#AAAAAA",
    200: "#3D3D3D",
    300: "#404040",
    400: "#282828",
    500: "#212121",
    600: "#181818",
    700: "#121212",
  },

  AGLightgray: {
    50: "#DADADA",
    100: "#CDCDCD",
    200: "#C0C0C0",
    300: "#B4B4B4",
    400: "#A7A7A7",
  },

  AGBrown: {
    50: "#f2ebe1",
    100: "#eee4d7",
    200: "#eadecd",
    300: "#e5d7c3",
    400: "#e1d0b9",
    500: "#ddcaaf",
    600: "#d9c3a5",
    700: "#d5bd9b",
  },

  AGLightBrown: "#F9F7F4",
  AGLightBlue: "#8ecae6",
  AGMidBlue: "#219ebc",
  AGYellow: "#ffb703",
  AGOrange: "#fb8500",
};

export default PaletteColors;
