import { Button, Chip, Rating, Tooltip, styled } from "@mui/material";
import InfoBox from "./InfoBox";
import SearchIcon from "@mui/icons-material/Search";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import {
  ConversationsApiFactory,
  Feedback,
  SearchType,
  Source,
  UpdateFeedbackModel,
} from "../api/api-gen-py-2/api";
import EvModal from "./Modal";
import FeedbackBox from "../functional-areas/evolved-search/FeedbackBox";
import { forwardRef, useCallback, useContext, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useApiGen } from "../api/hooks";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { MarkdownRenderer } from "./MarkdownRenderer/MarkdownRenderer";
import { PortalBoxContext } from "../functional-areas/portal-box/context";
import Hover from "./Hover";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { MenuBox } from "./ContextMenu/FloatingMenu";
import ContextMenuWrapper from "./ContextMenu/ContexMenuWrapper";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

const StyledRating = styled(Rating)({
  height: "16px",
  "& .MuiRating-iconFilled": {
    height: "16px",
    color: "#212121",
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
  "& .MuiRating-iconEmpty": {
    height: "16px",
    color: "#212121",
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
});

type Abstract = {
  text: string;
  score: number;
};

type Query = {
  text: string;
  context: string;
  tagsOrKeywors: string[];
  articlesReferences: string[];
  temperature: number;
  chainLogic: string;
};

type Response = {
  query: Query;
  abstract: Abstract;
  sources: Source[];
  correlatedQuestions: string[];
  date: string;
  uuid?: string;
  feedback?: Feedback;
  searchType?: SearchType;
  questionContext?: string[];
};

const ResponseBox = forwardRef(function ResponseBox(
  {
    response,
    searchId,
    active = false,
    onFeedbackSave,
  }: {
    response: Response;
    searchId?: string;
    active?: boolean;
    onFeedbackSave?: () => void;
  },
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const { query, abstract, sources, date, uuid, feedback, questionContext } =
    response;
  const [showSources, setShowSources] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const conversationApi = useApiGen(ConversationsApiFactory);

  const feedbackUpdate = useCallback(
    async (
      conversationUuid: string,
      questionUuid: string,
      updateFeedbackModel: UpdateFeedbackModel
    ) => {
      try {
        await conversationApi.updateFeedbackConversationsSetFeedbackConversationUuidQuestionUuidPut(
          conversationUuid,
          questionUuid,
          updateFeedbackModel
        );
        enqueueSnackbar("Feedback Saved", {
          variant: "success",
        });
        onFeedbackSave?.();
      } catch (e) {
        enqueueSnackbar("Servizio al momento non disponibile", {
          variant: "error",
        });
      }
    },
    [conversationApi]
  );

  const columns: TypeColumn[] = [
    {
      name: "art_number",
      header: "Art.",
      defaultFlex: 1,
      render: ({ data }) => <>{data.art_number}</>,
    },
    {
      name: "title",
      header: "Title",
      defaultFlex: 8,
      render: ({ data }) => (
        <div className="flex space-x-1">
          <EvModal
            title="Extended source"
            description={data.doc}
            activator={<span>{data.title ?? data.doc}</span>}
          />
        </div>
      ),
    },
    {
      name: "collection",
      header: "Collection",
      defaultFlex: 2,
      render: ({ data }) => <>{data.collection}</>,
    },
    {
      name: "score",
      header: "Score",
      defaultFlex: 1,
      render: ({ data }) => <>{data.score.toFixed(4)}</>,
    },
  ];

  return (
    <div className="mb-2" ref={ref}>
      <InfoBox
        icon={<SearchIcon fontSize="small" sx={{ marginRight: 1 }} />}
        title={
          <div className="flex space-x-2">
            <h2 className="font-bold text-base">Question</h2>
            {response.searchType && (
              <Chip
                label={response.searchType}
                variant="outlined"
                size="small"
              />
            )}
            {questionContext && questionContext.length && (
              <Tooltip
                title={
                  <>
                    {questionContext.map((el, index) => (
                      <p key={`el-${index}`}>{el}</p>
                    ))}
                  </>
                }
                arrow
              >
                <Chip
                  label={`${questionContext.length}`}
                  icon={<InsertDriveFileOutlinedIcon />}
                  variant="outlined"
                  size="small"
                  sx={{ px: 0.5 }}
                />
              </Tooltip>
            )}
            {feedback && (
              <Tooltip title={feedback.text} arrow>
                <div className="rounded-full border border-[#b2b2b2] flex justify-center align-middle fit-content px-1 py-[3px] h-full">
                  <StyledRating
                    disabled={true}
                    value={feedback.value}
                    icon={<StarIcon />}
                    emptyIcon={<StarBorderIcon />}
                  />
                </div>
              </Tooltip>
            )}
            <button onClick={() => setShowSources((el) => !el)}>
              <Chip
                label={`${sources.length}`}
                icon={<LeaderboardIcon />}
                variant="outlined"
                size="small"
                sx={{ px: 0.5 }}
              />
            </button>
          </div>
        }
        className="border border-gray-200 p-8 shadow-sm rounded-lg"
        subtitle={date}
        description={<p className="pt-4">"{query.text}"</p>}
        collapseDefault={active}
        collapsable={
          <div className="w-full">
            <div className="mt-5 border-gray border-t-[1px] pt-4">
              <div className="flex my-3">
                <h2 className="font-bold text-base mb-4">Abstract</h2>
                <Hover
                  className="flex"
                  elementHover={
                    <DragIndicatorIcon className="text-black ml-2" />
                  }
                >
                  <div className="absolute block z-20">
                    <MenuBox selectedContent={abstract.text} noSelection />
                  </div>
                </Hover>
              </div>
              <div className="text-sm">
                <ContextMenuWrapper text={abstract.text}>
                  <MarkdownContent
                    onValueSelection={() => console.log("selection")}
                    searchId={response.uuid ?? ""}
                  >
                    {abstract.text}
                  </MarkdownContent>
                </ContextMenuWrapper>
              </div>
            </div>
            {showSources && (
              <div className="mt-6 border-gray border-t-[1px] pt-4 max-w-full">
                <h2 className="font-bold text-base mb-4">Sources</h2>
                <div className="relative z-10 text-sm">
                  <ReactDataGrid
                    columns={columns}
                    dataSource={sources}
                    updateMenuPositionOnColumnsChange
                  />
                </div>
              </div>
            )}

            {showActions && (
              <div className="mt-4 border-gray border-t-[1px] pt-4">
                <div className="flex justify-end space-x-2">
                  {/* <Button variant="outlined">Tell me more</Button>
                <Button variant="outlined">Be more specific on..</Button> */}
                  {searchId && uuid && (
                    <FeedbackBox
                      mode="Modal"
                      currentFeedback={feedback}
                      onSave={({ text, value }) => {
                        feedbackUpdate(searchId, uuid, {
                          text: text ?? null,
                          value: value ?? null,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        }
      ></InfoBox>
    </div>
  );
});

function MarkdownContent({
  children,
  searchId,
}: {
  children: string;
  searchId: string;
  onValueSelection?: (value: string) => void;
}): JSX.Element {
  const { state } = useContext(PortalBoxContext);

  return (
    <MarkdownRenderer
      componentOverrides={{
        a: ({ node: _node, ...props }) => (
          <button
            type="button"
            className={`underline font-bold`}
            onClick={() => {
              if (!state.wiki?.wikiSearch) {
                return;
              }
              state.wiki?.wikiSearch(String(props.children), searchId);
            }}
          >
            {props.children}
          </button>
        ),
      }}
    >
      {children}
    </MarkdownRenderer>
  );
}

export default ResponseBox;
